<template>
	<div id="app">
		<Header />
		<transition name="fade">
			<router-view></router-view>
		</transition>
		<Footer v-if="$route.name !== 'main'" />
		<TopButton />
	</div>
</template>

<script>
import Header from "@/components/common/header";
import Footer from "@/components/common/footer";
import TopButton from "@/components/modules/topButton";

export default {
	name: "App",
	data: () => {
		return {};
	},
	components: {
		Header,
		Footer,
		TopButton,
	},
};
</script>

<style>
@import "./assets/css/modules.min.css";
@import "./assets/font/NotoSansKr.css";
@import "./assets/font/Ubuntu.css";
</style>
