<template>
	<section id="contact" class="contact subpage">
		<div class="head">
			<div class="wrapper">
				<div class="head__left">
					<h2 class="head__title">contact</h2>
				</div>
				<div class="head__right">
					<p>서울 영등포구 양평로 30길 14 세종앤까뮤스퀘어 9층 905호</p>
					<p>
						<b>TEL. 02-2677-0212</b>
						<b>FAX. 02-2677-0218</b>
					</p>
				</div>
			</div>
		</div>
		<article class="request">
			<div class="wrapper">
				<div class="request__context">
					<h3 class="request__title">컨설팅의뢰 및 문의사항</h3>
					<a href="mailto:master@netcomm.co.kr" class="request__link"
						>master@netcomm.co.kr</a
					>
				</div>
				<img
					src="@/assets/images/contact/contact_image0.jpg"
					alt=""
					class="request__image"
				/>
			</div>
		</article>
		<article class="directions">
			<div class="wrapper">
				<h3 class="hidden">오시는 길</h3>
				<div class="directions__transport directions__transport--subway">
					<p class="directions__title"><strong>Subway</strong></p>
					<ul class="directions__list">
						<li class="directions__item">
							<i class="directions__icon" style="background-color: #d1a62c">9</i>
							<span>선유도역 1번 출구</span>
						</li>
					</ul>
				</div>
				<div class="directions__transport directions__transport--bus">
					<p class="directions__title">
						<strong>BUS</strong> 한신아파트. 선유도역 (19210)
					</p>
					<ul class="directions__list">
						<li class="directions__item">
							<i class="directions__icon" style="background-color: #395cb4">B</i>
							<span>간선버스 603</span>
						</li>
						<li class="directions__item">
							<i class="directions__icon" style="background-color: #41b147">B</i>
							<span>지선버스 5620, 6514, 6620</span>
						</li>
						<li class="directions__item">
							<i class="directions__icon" style="background-color: #395cb4">B</i>
							<span>좌석버스 700</span>
						</li>
					</ul>
				</div>
			</div>
		</article>
		<article class="map">
			<h3 class="hidden">지도</h3>
			<div id="map"></div>
		</article>
	</section>
</template>

<script>
export default {
	name: "Contact",
	created: function() {
		setTimeout(() => {
			document.querySelector(".request__image").classList.add("animate")
		}, 500);
	},
	mounted() {
		window.kakao && window.kakao.maps
			? this.initMap()
			: this.addKakaoMapScript();
	},
	methods: {
		addKakaoMapScript() {
			const script = document.createElement("script");
			/* global kakao */
			script.onload = () => kakao.maps.load(this.initMap);
			script.src =
				"http://dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=e48aa8de7d9cb606ec64fb561804e1ac";
			document.head.appendChild(script);
		},
		initMap() {
			var container = document.getElementById("map"); //지도를 담을 영역의 DOM 레퍼런스
			var options = {
				//지도를 생성할 때 필요한 기본 옵션
				center: new kakao.maps.LatLng(37.54120848235676, 126.88935449780757), //지도의 중심좌표.
				level: 3, //지도의 레벨(확대, 축소 정도)
			};

			var map = new kakao.maps.Map(container, options); //지도 생성 및 객체 리턴

			// 마커가 표시될 위치입니다
			var markerPosition = new kakao.maps.LatLng(37.54120848235676, 126.88935449780757);

			// 마커를 생성합니다
			var marker = new kakao.maps.Marker({
				position: markerPosition,
			});

			// 마커가 지도 위에 표시되도록 설정합니다
			marker.setMap(map);
		},
	},
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/scss/contact";
</style>