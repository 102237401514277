<template>
	<article id="" class="projectView">
		<div class="detail">
			<div class="wrapper">
				<img :src="viewData.image.detail" alt="" class="detail__image" />
				<div class="detail__context">
					<h3 class="detail__title detail__title--en">
						{{ viewData.titleEn }}
					</h3>
					<strong class="detail__title detail__title--kr">{{
						viewData.titleKr
					}}</strong>
					<span class="detail__date">{{ viewData.date }}</span>
				</div>
			</div>
		</div>
		<div class="gallery">
			<div class="wrapper">
				<div
					v-for="galleryImage in viewData.image.gallery"
					:key="galleryImage.key"
					class="gallery__image"
				>
					<transition>
						<img :src="galleryImage" alt="" />
					</transition>
				</div>
			</div>
		</div>
		<div v-if="viewDataAll.length > 1" class="navigation">
			<router-link
				:to="$route.fullPath.replace($route.params.index, prevIndex)"
				class="navigation__link"
			>
				<img
					:src="viewDataAll[prevIndex].image.detail"
					class="navigation__image"
					alt=""
				/>
				<div class="navigation__contents">
					<div class="navigation__button navigation__button--prev">
						<img
							src="@/assets/images/project/navigation_button_prev.png"
							alt=""
						/>
					</div>
					<div class="navigation__context">
						<span class="navigation__title navigation__title--en">
							{{ viewDataAll[prevIndex].titleEn }}
						</span>
						<small class="navigation__title navigation__title--kr">
							{{ viewDataAll[prevIndex].titleKr }}
						</small>
					</div>
				</div>
			</router-link>
			<router-link
				v-if="viewDataAll.length > 2"
				:to="$route.fullPath.replace($route.params.index, nextIndex)"
				class="navigation__link"
			>
				<img
					:src="viewDataAll[nextIndex].image.detail"
					class="navigation__image"
					alt=""
				/>
				<div class="navigation__contents">
					<div class="navigation__button navigation__button--next">
						<img
							src="@/assets/images/project/navigation_button_next.png"
							alt=""
						/>
					</div>
					<div class="navigation__context">
						<span class="navigation__title navigation__title--en">
							{{ viewDataAll[nextIndex].titleEn }}
						</span>
						<small class="navigation__title navigation__title--kr">
							{{ viewDataAll[nextIndex].titleKr }}
						</small>
					</div>
				</div>
			</router-link>
		</div>
	</article>
</template>

<script>
import projectData from "./project-data";
import Tabs from "@/components/modules/tabs";

function imageGrid() {
	setTimeout(() => {
		document.querySelectorAll(".gallery__image").forEach((item) => {
			let image = item.childNodes[0];

			if (700 > image.width && 500 > image.height) {
				item.style.width = "auto";
			}
		});
	}, 2000);
}

projectData.scrollY = 0;
projectData.timer = null;

export default {
	name: "Project",
	components: {
		Tabs,
	},
	data: () => projectData,
	computed: {
		viewDataAll: function () {
			let data = this.projectList.filter((item) => {
				return item.type == this.$route.name;
			});
			return data;
		},
		viewData: function () {
			let data = this.projectList.filter((item) => {
				return item.type == this.$route.name;
			});
			data = data.splice(this.$route.params.index, 1);
			return data[0];
		},
		prevIndex: function () {
			let prevIndex;
			Number(this.$route.params.index) - 1 == -1
				? (prevIndex = Number(this.viewDataAll.length) - 1)
				: (prevIndex = Number(this.$route.params.index) - 1);
			return prevIndex;
		},
		nextIndex: function () {
			let nextIndex;
			if (Number(this.viewDataAll.length) != 2) {
				Number(this.$route.params.index) + 1 == Number(this.viewDataAll.length)
					? (nextIndex = 0)
					: (nextIndex = Number(this.$route.params.index) + 1);
			}
			return nextIndex;
		},
	},
	mounted() {
		window.scrollTo(0, 0);
		this.$nextTick(function () {
			imageGrid();
		});
		window.addEventListener("scroll", this.handleScroll);
	},
	updated() {
		window.scrollTo(0, 0);
		this.$nextTick(function () {
			imageGrid();
		});
		document
			.querySelector(".projectView")
			.animate([{ opacity: 0 }, { opacity: 1 }], {
				duration: 1000,
				easing: "linear",
			});
		document.querySelectorAll(".gallery__image").forEach((item) => {
			item.classList.remove("animate");
		});
	},
	beforeDestroy: function () {
		window.removeEventListener("scroll", this.handleScroll);
	},
	methods: {
		handleScroll: function () {
			var article = document.querySelectorAll(".gallery__image");
			if (this.timer === null) {
				this.timer = setTimeout(() => {
					this.scrollY = window.scrollY;
					article.forEach((item) => {
						var articleTop =
							window.pageYOffset + item.getBoundingClientRect().top - 500;
						if (this.scrollY >= articleTop) {
							item.classList.add("animate");
						}
					});
					clearTimeout(this.timer);
					this.timer = null;
				}, 1);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
</style>
