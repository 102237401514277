// import 'babel-polyfill';
import Vue from 'vue';
import App from './App.vue';
import router from './router';

import VueFullpage from 'fullpage-vue';
import 'animate.css';
import 'fullpage-vue/src/fullpage.css';
Vue.use(VueFullpage);

import CoomonJs from "./assets/js/common";

Vue.use(CoomonJs);

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    router,
}).$mount('#app')