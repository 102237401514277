<template>
	<div class="header" :class="{ 'header--main': $route.name == 'main' }">
		<div class="wrapper">
			<h1>
				<router-link :to="'/'">
					<img
						:src="
							$route.name == 'main'
								? require('@/assets/images/common/logo_white.png')
								: require('@/assets/images/common/logo.png')
						"
						class="header__logo"
						alt="Netcomm"
					/>
				</router-link>
				<div class="hidden">Netcomm</div>
			</h1>
			<transition name="fade">
				<ul
					v-if="gnbActive"
					:class="{ active: !isDesktop }"
					id="gnb"
					class="gnb"
				>
					<li
						v-for="item in items"
						:key="item.key"
						class="gnb__item"
						class-active="active"
					>
						<router-link :to="item.link">{{ item.title }} </router-link>
					</li>
				</ul>
			</transition>
			<transition name="fade">
				<button
					v-if="!isDesktop"
					@click="gnbToggle()"
					:class="{ active: gnbActive }"
					type="button"
					class="gnb__button"
				>
					<span class="bar bar1"></span>
					<span class="bar bar2"></span>
					<span class="bar bar3"></span>
				</button>
			</transition>
		</div>
	</div>
</template>

<script>
var responQuarter = 1025;
export default {
	name: "Header",
	data() {
		return {
			publicPath: process.env.BASE_URL,
			items: [
				{ link: "/about", title: "about" },
				{ link: "/works/consulting", title: "works" },
				{ link: "/project/all/list/0", title: "project" },
				{ link: "/contact", title: "contact" },
			],
			gnbActive: true,
			isDesktop: true,
		};
	},
	mounted() {
		if (this.isLaptop()) {
			this.isDesktop = false;
			this.gnbActive = false;
		}
		window.addEventListener("resize", this.handleResize);
	},
	updated() {
		if (this.isLaptop()) {
			if (this.gnbActive) {
				document.querySelector(".gnb").onclick = this.gnbToggle;
			}
		}
	},
	watch: {
		$route(to, from) {
			if (!this.isDesktop && this.gnbActive) {
				this.gnbActive = false;
				document.querySelector("body").style.overflow = "visible";
			}
		},
	},
	methods: {
		handleResize(event) {
			if (this.isLaptop()) {
				this.isDesktop = false;
				this.gnbActive = false;
			} else {
				this.isDesktop = true;
				this.gnbActive = true;
			}
		},
		gnbToggle(e) {
			console.log(e);
			if (e != undefined) {
				if (e.target.children.length != 0) {
					this.gnbActive
						? ((this.gnbActive = false),
						  (document.querySelector("body").style.overflow = "visible"))
						: ((this.gnbActive = true),
						  (document.querySelector("body").style.overflow = "hidden"));
				}
			} else {
				this.gnbActive
					? ((this.gnbActive = false),
					  (document.querySelector("body").style.overflow = "visible"))
					: ((this.gnbActive = true),
					  (document.querySelector("body").style.overflow = "hidden"));
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/scss/common";
</style>
