export default {
    tabsList: [
        { link: "/project/all/list/0", title: "전체" },
        { link: "/project/university/list/0", title: "대학도서관" },
        { link: "/project/public/list/0", title: "공공도서관" },
        { link: "/project/etc/list/0", title: "기타도서관" },
    ],
    projectList: [
        {
            type: "university",
            titleKr: "부산대학교 미리내열람실",
            titleEn: "Pusan National University Library",
            date: "2023",
            image: {
                thumbnail: require("@/assets/images/project/university/pnu_read_thumbnail.jpg"),
                detail: require("@/assets/images/project/university/pnu_read_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/university/pnu_read_gallery0.jpg"),
                    require("@/assets/images/project/university/pnu_read_gallery1.jpg"),
                    require("@/assets/images/project/university/pnu_read_gallery2.jpg"),
                    require("@/assets/images/project/university/pnu_read_gallery3.jpg"),
                    require("@/assets/images/project/university/pnu_read_gallery4.jpg"),
                ],
            },
            mainSelected: false,
            referenceSelected: true,
        },
        {
            type: "public",
            titleKr: "전포어울더울 작은도서관",
            titleEn: "Jeonpo Eouldeoul Small Library",
            date: "2023",
            image: {
                thumbnail: require("@/assets/images/project/public/jeonpo_thumbnail.jpg"),
                detail: require("@/assets/images/project/public/jeonpo_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/public/jeonpo_gallery0.jpg"),
                    require("@/assets/images/project/public/jeonpo_gallery1.jpg"),
                    require("@/assets/images/project/public/jeonpo_gallery2.jpg"),
                ],
            },
            mainSelected: false,
            referenceSelected: true,
        },
        {
            type: "university",
            titleKr: "경상국립대학교 도서관 스마트스터디룸",
            titleEn: "GYEONGSANG NATIONAL UNIVERSITY LIBRARY",
            date: "2023",
            image: {
                thumbnail: require("@/assets/images/project/university/gnu_smart_thumbnail.jpg"),
                detail: require("@/assets/images/project/university/gnu_smart_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/university/gnu_smart_gallery0.jpg"),
                    require("@/assets/images/project/university/gnu_smart_gallery1.jpg"),
                    require("@/assets/images/project/university/gnu_smart_gallery2.jpg"),
                    require("@/assets/images/project/university/gnu_smart_gallery3.jpg"),
                    require("@/assets/images/project/university/gnu_smart_gallery4.jpg"),
                    require("@/assets/images/project/university/gnu_smart_gallery5.jpg"),
                    require("@/assets/images/project/university/gnu_smart_gallery6.jpg"),
                    require("@/assets/images/project/university/gnu_smart_gallery7.jpg"),
                    require("@/assets/images/project/university/gnu_smart_gallery8.jpg"),
                    require("@/assets/images/project/university/gnu_smart_gallery9.jpg"),
                ],
            },
            mainSelected: false,
            referenceSelected: true,
        },
        {
            type: "university",
            titleKr: "인천대학교 학산도서관",
            titleEn: "Incheon National University Library",
            date: "2023",
            image: {
                thumbnail: require("@/assets/images/project/university/inu_thumbnail.jpg"),
                detail: require("@/assets/images/project/university/inu_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/university/inu_gallery0.jpg"),
                    require("@/assets/images/project/university/inu_gallery1.jpg"),
                    require("@/assets/images/project/university/inu_gallery2.jpg"),
                    require("@/assets/images/project/university/inu_gallery3.jpg"),
                    require("@/assets/images/project/university/inu_gallery4.jpg"),
                    require("@/assets/images/project/university/inu_gallery5.jpg"),
                    require("@/assets/images/project/university/inu_gallery6.jpg"),
                    require("@/assets/images/project/university/inu_gallery7.jpg"),
                    require("@/assets/images/project/university/inu_gallery8.jpg"),
                    require("@/assets/images/project/university/inu_gallery9.jpg"),
                    require("@/assets/images/project/university/inu_gallery10.jpg"),
                    require("@/assets/images/project/university/inu_gallery11.jpg"),
                    require("@/assets/images/project/university/inu_gallery12.jpg"),
                    require("@/assets/images/project/university/inu_gallery13.jpg"),
                    require("@/assets/images/project/university/inu_gallery14.jpg"),
                    require("@/assets/images/project/university/inu_gallery15.jpg"),
                    require("@/assets/images/project/university/inu_gallery16.jpg"),
                    require("@/assets/images/project/university/inu_gallery17.jpg"),
                    require("@/assets/images/project/university/inu_gallery18.jpg"),
                ],
            },
            mainSelected: true,
            mainImage: [
                require("@/assets/images/project/university/inu_main0.jpg"),
                require("@/assets/images/project/university/inu_main1.jpg"),
                require("@/assets/images/project/university/inu_main2.jpg"),
            ],
            referenceSelected: true,
        },
        {
            type: "university",
            titleKr: "한국체육대학교 메타버스 학술정보원",
            titleEn: "KOREA NATIONAL SPORT UNIVERSITY METAVERSE LIBRARY",
            date: "2023",
            image: {
                thumbnail: require("@/assets/images/project/university/knsu_thumbnail.jpg"),
                detail: require("@/assets/images/project/university/knsu_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/university/knsu_gallery0.jpg"),
                    require("@/assets/images/project/university/knsu_gallery1.jpg"),
                    require("@/assets/images/project/university/knsu_gallery2.jpg"),
                    require("@/assets/images/project/university/knsu_gallery3.jpg"),
                    require("@/assets/images/project/university/knsu_gallery4.jpg"),
                    require("@/assets/images/project/university/knsu_gallery5.jpg"),
                    require("@/assets/images/project/university/knsu_gallery6.jpg"),
                    require("@/assets/images/project/university/knsu_gallery7.jpg"),
                    require("@/assets/images/project/university/knsu_gallery8.jpg"),
                    require("@/assets/images/project/university/knsu_gallery9.jpg"),
                    require("@/assets/images/project/university/knsu_gallery10.jpg"),
                    require("@/assets/images/project/university/knsu_gallery11.jpg"),
                    require("@/assets/images/project/university/knsu_gallery12.jpg"),
                    require("@/assets/images/project/university/knsu_gallery13.jpg"),
                ],
            },
            mainSelected: true,
            mainImage: [
                require("@/assets/images/project/university/knsu_main0.jpg"),
                require("@/assets/images/project/university/knsu_main1.jpg"),
                require("@/assets/images/project/university/knsu_main2.jpg"),
            ],
            referenceSelected: true,
        },
        {
            type: "university",
            titleKr: "부산대학교 새벽벌도서관",
            titleEn: "Pusan National University Library",
            date: "2023",
            image: {
                thumbnail: require("@/assets/images/project/university/pnu_thumbnail.jpg"),
                detail: require("@/assets/images/project/university/pnu_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/university/pnu_gallery0.jpg"),
                    require("@/assets/images/project/university/pnu_gallery1.jpg"),
                    require("@/assets/images/project/university/pnu_gallery2.jpg"),
                    require("@/assets/images/project/university/pnu_gallery3.jpg"),
                    require("@/assets/images/project/university/pnu_gallery4.jpg"),
                ],
            },
            mainSelected: true,
            mainImage: [
                require("@/assets/images/project/university/pnu_main0.jpg"),
                require("@/assets/images/project/university/pnu_main1.jpg"),
                require("@/assets/images/project/university/pnu_main2.jpg"),
            ],
            referenceSelected: true,
        },
        {
            type: "public",
            titleKr: "책테마파크도서관",
            titleEn: "Seongnam Book Theme Park Library",
            date: "2022",
            image: {
                thumbnail: require("@/assets/images/project/public/snpark_thumbnail.jpg"),
                detail: require("@/assets/images/project/public/snpark_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/public/snpark_gallery0.jpg"),
                    require("@/assets/images/project/public/snpark_gallery1.jpg"),
                    require("@/assets/images/project/public/snpark_gallery2.jpg"),
                    require("@/assets/images/project/public/snpark_gallery3.jpg"),
                    require("@/assets/images/project/public/snpark_gallery4.jpg"),
                ],
            },
            mainSelected: false,
            referenceSelected: true,
        },
        {
            type: "public",
            titleKr: "남산도서관",
            titleEn: "Namsan Public Library",
            date: "2022",
            image: {
                thumbnail: require("@/assets/images/project/public/namsan_thumbnail.jpg"),
                detail: require("@/assets/images/project/public/namsan_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/public/namsan_gallery0.jpg"),
                    require("@/assets/images/project/public/namsan_gallery1.jpg"),
                    require("@/assets/images/project/public/namsan_gallery2.jpg"),
                    require("@/assets/images/project/public/namsan_gallery3.jpg"),
                    require("@/assets/images/project/public/namsan_gallery4.jpg"),
                    require("@/assets/images/project/public/namsan_gallery5.jpg"),
                    require("@/assets/images/project/public/namsan_gallery6.jpg"),
                ],
            },
            mainSelected: false,
            referenceSelected: true,
        },
        {
            type: "university",
            titleKr: "강원대학교 도서관",
            titleEn: "KANGWON NATIONAL UNIVERSITY LIBRARY",
            date: "2022",
            image: {
                thumbnail: require("@/assets/images/project/university/knu2_thumbnail.jpg"),
                detail: require("@/assets/images/project/university/knu2_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/university/knu2_gallery0.jpg"),
                    require("@/assets/images/project/university/knu2_gallery1.jpg"),
                    require("@/assets/images/project/university/knu2_gallery2.jpg"),
                    require("@/assets/images/project/university/knu2_gallery3.jpg"),
                    require("@/assets/images/project/university/knu2_gallery4.jpg"),
                    require("@/assets/images/project/university/knu2_gallery5.jpg"),
                    require("@/assets/images/project/university/knu2_gallery6.jpg"),
                    require("@/assets/images/project/university/knu2_gallery7.jpg"),
                    require("@/assets/images/project/university/knu2_gallery8.jpg"),
                    require("@/assets/images/project/university/knu2_gallery9.jpg"),
                    require("@/assets/images/project/university/knu2_gallery10.jpg"),
                    require("@/assets/images/project/university/knu2_gallery11.jpg"),
                    require("@/assets/images/project/university/knu2_gallery12.jpg"),
                    require("@/assets/images/project/university/knu2_gallery13.jpg"),
                    require("@/assets/images/project/university/knu2_gallery14.jpg"),
                    require("@/assets/images/project/university/knu2_gallery15.jpg"),
                    require("@/assets/images/project/university/knu2_gallery16.jpg"),
                    require("@/assets/images/project/university/knu2_gallery17.jpg"),
                    require("@/assets/images/project/university/knu2_gallery18.jpg"),
                    require("@/assets/images/project/university/knu2_gallery19.jpg"),
                    require("@/assets/images/project/university/knu2_gallery20.jpg"),
                ],
            },
            mainSelected: true,
            mainImage: [
                require("@/assets/images/project/university/knu2_main0.jpg"),
                require("@/assets/images/project/university/knu2_main1.jpg"),
                require("@/assets/images/project/university/knu2_main2.jpg"),
            ],
            referenceSelected: true,
        },
        {
            type: "public",
            titleKr: "회화나무 작은도서관",
            titleEn: "Hoehwanamu Small Library",
            date: "2022",
            image: {
                thumbnail: require("@/assets/images/project/public/hhnm_thumbnail.jpg"),
                detail: require("@/assets/images/project/public/hhnm_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/public/hhnm_gallery0.jpg"),
                    require("@/assets/images/project/public/hhnm_gallery1.jpg"),
                    require("@/assets/images/project/public/hhnm_gallery2.jpg"),
                    require("@/assets/images/project/public/hhnm_gallery3.jpg"),
                    require("@/assets/images/project/public/hhnm_gallery4.jpg"),
                ],
            },
            mainSelected: false,
            referenceSelected: true,
        },
        {
            type: "public",
            titleKr: "광주 신용 복합공공도서관",
            titleEn: "ShinYong Library",
            date: "2022",
            image: {
                thumbnail: require("@/assets/images/project/public/kjsy_thumbnail.jpg"),
                detail: require("@/assets/images/project/public/kjsy_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/public/kjsy_gallery0.jpg"),
                    require("@/assets/images/project/public/kjsy_gallery1.jpg"),
                    require("@/assets/images/project/public/kjsy_gallery2.jpg"),
                    require("@/assets/images/project/public/kjsy_gallery3.jpg"),
                    require("@/assets/images/project/public/kjsy_gallery4.jpg"),
                    require("@/assets/images/project/public/kjsy_gallery5.jpg"),
                    require("@/assets/images/project/public/kjsy_gallery6.jpg"),
                    require("@/assets/images/project/public/kjsy_gallery7.jpg"),
                    require("@/assets/images/project/public/kjsy_gallery8.jpg"),
                    require("@/assets/images/project/public/kjsy_gallery9.jpg"),
                    require("@/assets/images/project/public/kjsy_gallery10.jpg"),
                    require("@/assets/images/project/public/kjsy_gallery11.jpg"),
                ],
            },
            mainSelected: false,
            referenceSelected: true,
        },
        {
            type: "public",
            titleKr: "광주 중흥 복합공공도서관",
            titleEn: "JungHeung Library",
            date: "2022",
            image: {
                thumbnail: require("@/assets/images/project/public/kjjh_thumbnail.jpg"),
                detail: require("@/assets/images/project/public/kjjh_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/public/kjjh_gallery0.jpg"),
                    require("@/assets/images/project/public/kjjh_gallery1.jpg"),
                    require("@/assets/images/project/public/kjjh_gallery2.jpg"),
                    require("@/assets/images/project/public/kjjh_gallery3.jpg"),
                    require("@/assets/images/project/public/kjjh_gallery4.jpg"),
                    require("@/assets/images/project/public/kjjh_gallery5.jpg"),
                    require("@/assets/images/project/public/kjjh_gallery6.jpg"),
                    require("@/assets/images/project/public/kjjh_gallery7.jpg"),
                    require("@/assets/images/project/public/kjjh_gallery8.jpg"),
                    require("@/assets/images/project/public/kjjh_gallery9.jpg"),
                    require("@/assets/images/project/public/kjjh_gallery10.jpg"),
                    require("@/assets/images/project/public/kjjh_gallery11.jpg"),
                ],
            },
            mainSelected: false,
            referenceSelected: true,
        },
        {
            type: "public",
            titleKr: "흑석동 복합도서관 (까망돌도서관)",
            titleEn: "kkamangdol Libray",
            date: "2022",
            image: {
                thumbnail: require("@/assets/images/project/public/kkam_thumbnail.jpg"),
                detail: require("@/assets/images/project/public/kkam_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/public/kkam_gallery0.jpg"),
                    require("@/assets/images/project/public/kkam_gallery1.jpg"),
                ],
            },
            mainSelected: false,
            referenceSelected: true,
        },
        {
            type: "public",
            titleKr: "국회부산도서관",
            titleEn: "National Assembly Busan Library",
            date: "2022",
            image: {
                thumbnail: require("@/assets/images/project/public/nabl_thumbnail.jpg"),
                detail: require("@/assets/images/project/public/nabl_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/public/nabl_gallery0.jpg"),
                    require("@/assets/images/project/public/nabl_gallery1.jpg"),
                    require("@/assets/images/project/public/nabl_gallery2.jpg"),
                    require("@/assets/images/project/public/nabl_gallery3.jpg"),
                    require("@/assets/images/project/public/nabl_gallery4.jpg"),
                    require("@/assets/images/project/public/nabl_gallery5.jpg"),
                    require("@/assets/images/project/public/nabl_gallery6.jpg"),
                    require("@/assets/images/project/public/nabl_gallery7.jpg"),
                    require("@/assets/images/project/public/nabl_gallery8.jpg"),
                    require("@/assets/images/project/public/nabl_gallery9.jpg"),
                    require("@/assets/images/project/public/nabl_gallery10.jpg"),
                    require("@/assets/images/project/public/nabl_gallery11.jpg"),
                    require("@/assets/images/project/public/nabl_gallery12.jpg"),
                    require("@/assets/images/project/public/nabl_gallery13.jpg"),
                    require("@/assets/images/project/public/nabl_gallery14.jpg"),
                    require("@/assets/images/project/public/nabl_gallery15.jpg"),
                ],
            },
            mainSelected: true,
            mainImage: [
                require("@/assets/images/project/public/nabl_main0.jpg"),
                require("@/assets/images/project/public/nabl_main1.jpg"),
                require("@/assets/images/project/public/nabl_main2.jpg"),
            ],
            referenceSelected: true,
        },
        {
            type: "public",
            titleKr: "세종시립도서관",
            titleEn: "Sejong City Library",
            date: "2021",
            image: {
                thumbnail: require("@/assets/images/project/public/sejong_thumbnail.jpg"),
                detail: require("@/assets/images/project/public/sejong_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/public/sejong_gallery0.jpg"),
                    require("@/assets/images/project/public/sejong_gallery1.jpg"),
                    require("@/assets/images/project/public/sejong_gallery2.jpg"),
                    require("@/assets/images/project/public/sejong_gallery3.jpg"),
                    require("@/assets/images/project/public/sejong_gallery4.jpg"),
                    require("@/assets/images/project/public/sejong_gallery5.jpg"),
                ],
            },
            mainSelected: false,
            referenceSelected: true,
        },
        {
            type: "public",
            titleKr: "여수시립현암도서관",
            titleEn: "Yeosu Municipal Hyeonam Library",
            date: "2021",
            image: {
                thumbnail: require("@/assets/images/project/public/yeosu_thumbnail.jpg"),
                detail: require("@/assets/images/project/public/yeosu_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/public/yeosu_gallery0.jpg"),
                    require("@/assets/images/project/public/yeosu_gallery1.jpg"),
                    require("@/assets/images/project/public/yeosu_gallery2.jpg"),
                    require("@/assets/images/project/public/yeosu_gallery3.jpg"),
                    require("@/assets/images/project/public/yeosu_gallery4.jpg"),
                ],
            },
            mainSelected: false,
            referenceSelected: true,
        },
        {
            type: "university",
            titleKr: "육군사관학교스마트도서관",
            titleEn: "KOREA MILITARY ACADEMY SMART LIBRARY",
            date: "2021",
            image: {
                thumbnail: require("@/assets/images/project/university/kma_thumbnail.jpg"),
                detail: require("@/assets/images/project/university/kma_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/university/kma_gallery0.jpg"),
                    require("@/assets/images/project/university/kma_gallery1.jpg"),
                    require("@/assets/images/project/university/kma_gallery2.jpg"),
                    require("@/assets/images/project/university/kma_gallery3.jpg"),
                    require("@/assets/images/project/university/kma_gallery4.jpg"),
                    require("@/assets/images/project/university/kma_gallery5.jpg"),
                    require("@/assets/images/project/university/kma_gallery6.jpg"),
                    require("@/assets/images/project/university/kma_gallery7.jpg"),
                    require("@/assets/images/project/university/kma_gallery8.jpg"),
                    require("@/assets/images/project/university/kma_gallery9.jpg"),
                    require("@/assets/images/project/university/kma_gallery10.jpg"),
                    require("@/assets/images/project/university/kma_gallery11.jpg"),
                    require("@/assets/images/project/university/kma_gallery12.jpg"),
                    require("@/assets/images/project/university/kma_gallery13.jpg"),
                    require("@/assets/images/project/university/kma_gallery14.jpg"),
                    require("@/assets/images/project/university/kma_gallery15.jpg"),
                    require("@/assets/images/project/university/kma_gallery16.jpg"),
                    require("@/assets/images/project/university/kma_gallery17.jpg"),
                ],
            },
            mainSelected: false,
            referenceSelected: true,
        },
        {
            type: "public",
            titleKr: "안동시립중앙도서관",
            titleEn: "Andong Municipal Library",
            date: "2020",
            image: {
                thumbnail: require("@/assets/images/project/public/andong_thumbnail.jpg"),
                detail: require("@/assets/images/project/public/andong_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/public/andong_gallery0.jpg"),
                    require("@/assets/images/project/public/andong_gallery1.jpg"),
                    require("@/assets/images/project/public/andong_gallery2.jpg"),
                    require("@/assets/images/project/public/andong_gallery3.jpg"),
                ],
            },
            mainSelected: false,
            referenceSelected: true,
        },
        




        {
            type: "university",
            titleKr: "충남대학교 도서관",
            titleEn: "Chungnam national university library",
            date: "2020",
            image: {
                thumbnail: require("@/assets/images/project/university/cnu_thumbnail.jpg"),
                detail: require("@/assets/images/project/university/cnu_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/university/cnu_gallery0.jpg"),
                    require("@/assets/images/project/university/cnu_gallery1.jpg"),
                    require("@/assets/images/project/university/cnu_gallery2.jpg"),
                    require("@/assets/images/project/university/cnu_gallery3.jpg"),
                    require("@/assets/images/project/university/cnu_gallery4.jpg"),
                    require("@/assets/images/project/university/cnu_gallery5.jpg"),
                    require("@/assets/images/project/university/cnu_gallery6.jpg"),
                    require("@/assets/images/project/university/cnu_gallery7.jpg"),
                    require("@/assets/images/project/university/cnu_gallery8.jpg"),
                    require("@/assets/images/project/university/cnu_gallery9.jpg"),
                    require("@/assets/images/project/university/cnu_gallery10.jpg"),
                    require("@/assets/images/project/university/cnu_gallery11.jpg"),
                    require("@/assets/images/project/university/cnu_gallery12.jpg"),
                ],
            },
            mainSelected: false,
            mainImage: [
                require("@/assets/images/project/university/cnu_main0.jpg"),
                require("@/assets/images/project/university/cnu_main1.jpg"),
                require("@/assets/images/project/university/cnu_main2.jpg"),
            ],
            referenceSelected: true,
        },
        {
            type: "university",
            titleKr: "경상대 해양과학 도서관",
            titleEn: "Gyeongsang National University Library",
            date: "2020",
            image: {
                thumbnail: require("@/assets/images/project/university/gnu_thumbnail.jpg"),
                detail: require("@/assets/images/project/university/gnu_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/university/gnu_gallery0.jpg"),
                    require("@/assets/images/project/university/gnu_gallery1.jpg"),
                    require("@/assets/images/project/university/gnu_gallery2.jpg"),
                    require("@/assets/images/project/university/gnu_gallery3.jpg"),
                    require("@/assets/images/project/university/gnu_gallery4.jpg"),
                    require("@/assets/images/project/university/gnu_gallery5.jpg"),
                    require("@/assets/images/project/university/gnu_gallery6.jpg"),
                    require("@/assets/images/project/university/gnu_gallery7.jpg"),
                    require("@/assets/images/project/university/gnu_gallery8.jpg"),
                    require("@/assets/images/project/university/gnu_gallery9.jpg"),
                ],
            },
            mainSelected: false,
            mainImage: [
                require("@/assets/images/project/university/gnu_main0.jpg"),
                require("@/assets/images/project/university/gnu_main1.jpg"),
                require("@/assets/images/project/university/gnu_main2.jpg"),
            ],
            referenceSelected: true,
        },
        {
            type: "public",
            titleKr: "서초청소년도서관",
            titleEn: "SEOCHO YOUTH LIBRARY",
            date: "2020",
            image: {
                thumbnail: require("@/assets/images/project/public/syl_thumbnail.jpg"),
                detail: require("@/assets/images/project/public/syl_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/public/syl_gallery0.jpg"),
                    require("@/assets/images/project/public/syl_gallery1.jpg"),
                    require("@/assets/images/project/public/syl_gallery2.jpg"),
                    require("@/assets/images/project/public/syl_gallery3.jpg"),
                    require("@/assets/images/project/public/syl_gallery4.jpg"),
                    require("@/assets/images/project/public/syl_gallery5.jpg"),
                    require("@/assets/images/project/public/syl_gallery6.jpg"),
                    require("@/assets/images/project/public/syl_gallery7.jpg"),
                    require("@/assets/images/project/public/syl_gallery8.jpg"),
                    require("@/assets/images/project/public/syl_gallery9.jpg"),
                    require("@/assets/images/project/public/syl_gallery10.jpg"),
                    require("@/assets/images/project/public/syl_gallery11.jpg"),
                    require("@/assets/images/project/public/syl_gallery12.jpg"),
                ],
            },
            mainSelected: false,
            mainImage: [
                require("@/assets/images/project/public/syl_main0.jpg"),
                require("@/assets/images/project/public/syl_main1.jpg"),
                require("@/assets/images/project/public/syl_main2.jpg"),
            ],
            referenceSelected: true,
        },
        {
            type: "university",
            titleKr: "국립 경남과학기술대학교",
            titleEn: "GYEONGNAM NATIONAL UNIVERSITY OF SCIENCE AND TECHNOLOGY",
            date: "2020",
            image: {
                thumbnail: require("@/assets/images/project/university/gntech_thumbnail.jpg"),
                detail: require("@/assets/images/project/university/gntech_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/university/gntech_gallery0.jpg"),
                    require("@/assets/images/project/university/gntech_gallery1.jpg"),
                    require("@/assets/images/project/university/gntech_gallery2.jpg"),
                    require("@/assets/images/project/university/gntech_gallery3.jpg"),
                    require("@/assets/images/project/university/gntech_gallery4.jpg"),
                    require("@/assets/images/project/university/gntech_gallery5.jpg"),
                    require("@/assets/images/project/university/gntech_gallery6.jpg"),
                    require("@/assets/images/project/university/gntech_gallery7.jpg"),
                    require("@/assets/images/project/university/gntech_gallery8.jpg"),
                    require("@/assets/images/project/university/gntech_gallery9.jpg"),
                    require("@/assets/images/project/university/gntech_gallery10.jpg"),
                ],
            },
            mainSelected: false,
            mainImage: [
                require("@/assets/images/project/university/gntech_main0.jpg"),
                require("@/assets/images/project/university/gntech_main1.jpg"),
                require("@/assets/images/project/university/gntech_main2.jpg"),
            ],
            referenceSelected: true,
        },
        {
            type: "public",
            titleKr: "부산도서관",
            titleEn: "Busan Metropolitan City Library",
            date: "2020",
            image: {
                thumbnail: require("@/assets/images/project/public/bmcl_thumbnail.jpg"),
                detail: require("@/assets/images/project/public/bmcl_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/public/bmcl_gallery0.jpg"),
                    require("@/assets/images/project/public/bmcl_gallery1.jpg"),
                    require("@/assets/images/project/public/bmcl_gallery2.jpg"),
                    require("@/assets/images/project/public/bmcl_gallery3.jpg"),
                    require("@/assets/images/project/public/bmcl_gallery4.jpg"),
                    require("@/assets/images/project/public/bmcl_gallery5.jpg"),
                    require("@/assets/images/project/public/bmcl_gallery6.jpg"),
                    require("@/assets/images/project/public/bmcl_gallery7.jpg"),
                    require("@/assets/images/project/public/bmcl_gallery8.jpg"),
                    require("@/assets/images/project/public/bmcl_gallery9.jpg"),
                    require("@/assets/images/project/public/bmcl_gallery10.jpg"),
                    require("@/assets/images/project/public/bmcl_gallery11.jpg"),
                    require("@/assets/images/project/public/bmcl_gallery12.jpg"),
                    require("@/assets/images/project/public/bmcl_gallery13.jpg"),
                    require("@/assets/images/project/public/bmcl_gallery14.jpg"),
                    require("@/assets/images/project/public/bmcl_gallery15.jpg"),
                    require("@/assets/images/project/public/bmcl_gallery16.jpg"),
                    require("@/assets/images/project/public/bmcl_gallery17.jpg"),
                    require("@/assets/images/project/public/bmcl_gallery18.jpg"),
                    require("@/assets/images/project/public/bmcl_gallery19.jpg"),
                ],
            },
            mainSelected: false,
            mainImage: [
                require("@/assets/images/project/public/bmcl_main0.jpg"),
                require("@/assets/images/project/public/bmcl_main1.jpg"),
                require("@/assets/images/project/public/bmcl_main2.jpg"),
            ],
            referenceSelected: true,
        },
        {
            type: "etc",
            titleKr: "헌법재판소",
            titleEn: "THE CONSTITUTIONAL COURT LIBRARY",
            date: "2020",
            image: {
                thumbnail: require("@/assets/images/project/etc/ccl_thumbnail.jpg"),
                detail: require("@/assets/images/project/etc/ccl_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/etc/ccl_gallery0.jpg"),
                    require("@/assets/images/project/etc/ccl_gallery1.jpg"),
                    require("@/assets/images/project/etc/ccl_gallery2.jpg"),
                    require("@/assets/images/project/etc/ccl_gallery3.jpg"),
                    require("@/assets/images/project/etc/ccl_gallery4.jpg"),
                    require("@/assets/images/project/etc/ccl_gallery5.jpg"),
                    require("@/assets/images/project/etc/ccl_gallery6.jpg"),
                    require("@/assets/images/project/etc/ccl_gallery7.jpg"),
                    require("@/assets/images/project/etc/ccl_gallery8.jpg"),
                    require("@/assets/images/project/etc/ccl_gallery9.jpg"),
                    require("@/assets/images/project/etc/ccl_gallery10.jpg"),
                    require("@/assets/images/project/etc/ccl_gallery11.jpg"),
                    require("@/assets/images/project/etc/ccl_gallery12.jpg"),
                    require("@/assets/images/project/etc/ccl_gallery13.jpg"),
                ],
            },
            mainSelected: false,
            mainImage: [
                require("@/assets/images/project/etc/ccl_main0.jpg"),
                require("@/assets/images/project/etc/ccl_main1.jpg"),
                require("@/assets/images/project/etc/ccl_main2.jpg"),
            ],
            referenceSelected: true,
        },
        {
            type: "etc",
            titleKr: "국토정보공사",
            titleEn: "National Land Information Corporationv",
            date: "2020",
            image: {
                thumbnail: require("@/assets/images/project/etc/nlic_thumbnail.jpg"),
                detail: require("@/assets/images/project/etc/nlic_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/etc/nlic_gallery0.jpg"),
                    require("@/assets/images/project/etc/nlic_gallery1.jpg"),
                    require("@/assets/images/project/etc/nlic_gallery2.jpg"),
                    require("@/assets/images/project/etc/nlic_gallery3.jpg"),
                    require("@/assets/images/project/etc/nlic_gallery4.jpg"),
                    require("@/assets/images/project/etc/nlic_gallery5.jpg"),
                    require("@/assets/images/project/etc/nlic_gallery6.jpg"),
                    require("@/assets/images/project/etc/nlic_gallery7.jpg"),
                    require("@/assets/images/project/etc/nlic_gallery8.jpg"),
                    require("@/assets/images/project/etc/nlic_gallery9.jpg"),
                    require("@/assets/images/project/etc/nlic_gallery10.jpg"),
                    require("@/assets/images/project/etc/nlic_gallery11.jpg"),
                ],
            },
            mainSelected: false,
            mainImage: [
                require("@/assets/images/project/etc/nlic_main0.jpg"),
                require("@/assets/images/project/etc/nlic_main1.jpg"),
                require("@/assets/images/project/etc/nlic_main2.jpg"),
            ],
            referenceSelected: true,
        },
        {
            type: "university",
            titleKr: "국립 강릉원주대학교",
            titleEn: "Gangneung-Wonju National University",
            date: "2019",
            image: {
                thumbnail: require("@/assets/images/project/university/gwnu_thumbnail.jpg"),
                detail: require("@/assets/images/project/university/gwnu_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/university/gwnu_gallery0.jpg"),
                    require("@/assets/images/project/university/gwnu_gallery1.jpg"),
                    require("@/assets/images/project/university/gwnu_gallery2.jpg"),
                    require("@/assets/images/project/university/gwnu_gallery3.jpg"),
                    require("@/assets/images/project/university/gwnu_gallery4.jpg"),
                    require("@/assets/images/project/university/gwnu_gallery5.jpg"),
                    require("@/assets/images/project/university/gwnu_gallery6.jpg"),
                    require("@/assets/images/project/university/gwnu_gallery7.jpg"),
                    require("@/assets/images/project/university/gwnu_gallery8.jpg"),
                ],
            },
            mainSelected: false,
            mainImage: [
                require("@/assets/images/project/university/gwnu_main0.jpg"),
                require("@/assets/images/project/university/gwnu_main1.jpg"),
                require("@/assets/images/project/university/gwnu_main2.jpg"),
            ],
            referenceSelected: true,
        },
        {
            type: "university",
            titleKr: "경북대 상주캠퍼스",
            titleEn: "Kyungpook National University Sangju Campus",
            date: "2019",
            image: {
                thumbnail: require("@/assets/images/project/university/knus_thumbnail.jpg"),
                detail: require("@/assets/images/project/university/knus_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/university/knus_gallery0.jpg"),
                    require("@/assets/images/project/university/knus_gallery1.jpg"),
                    require("@/assets/images/project/university/knus_gallery2.jpg"),
                    require("@/assets/images/project/university/knus_gallery3.jpg"),
                    require("@/assets/images/project/university/knus_gallery4.jpg"),
                    require("@/assets/images/project/university/knus_gallery5.jpg"),
                ],
            },
            mainSelected: false,
            mainImage: [
                require("@/assets/images/project/university/knus_main0.jpg"),
                require("@/assets/images/project/university/knus_main1.jpg"),
                require("@/assets/images/project/university/knus_main2.jpg"),
            ],
            referenceSelected: true,
        },
        {
            type: "public",
            titleKr: "하남미사도서관",
            titleEn: "HANAM CITY MISA LIBRARY",
            date: "2019",
            image: {
                thumbnail: require("@/assets/images/project/public/hcml_thumbnail.jpg"),
                detail: require("@/assets/images/project/public/hcml_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/public/hcml_gallery0.jpg"),
                    require("@/assets/images/project/public/hcml_gallery1.jpg"),
                    require("@/assets/images/project/public/hcml_gallery2.jpg"),
                    require("@/assets/images/project/public/hcml_gallery3.jpg"),
                    require("@/assets/images/project/public/hcml_gallery4.jpg"),
                    require("@/assets/images/project/public/hcml_gallery5.jpg"),
                    require("@/assets/images/project/public/hcml_gallery6.jpg"),
                    require("@/assets/images/project/public/hcml_gallery7.jpg"),
                    require("@/assets/images/project/public/hcml_gallery8.jpg"),
                    require("@/assets/images/project/public/hcml_gallery9.jpg"),
                    require("@/assets/images/project/public/hcml_gallery10.jpg"),
                    require("@/assets/images/project/public/hcml_gallery11.jpg"),
                    require("@/assets/images/project/public/hcml_gallery12.jpg"),
                ],
            },
            mainSelected: false,
            mainImage: [
                require("@/assets/images/project/public/hcml_main0.jpg"),
                require("@/assets/images/project/public/hcml_main1.jpg"),
                require("@/assets/images/project/public/hcml_main2.jpg"),
            ],
            referenceSelected: true,
        },
        {
            type: "public",
            titleKr: "여수이순신도서관",
            titleEn: "YEOSU MUNICIPAL LIBRARY",
            date: "2019",
            image: {
                thumbnail: require("@/assets/images/project/public/yml_thumbnail.jpg"),
                detail: require("@/assets/images/project/public/yml_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/public/yml_gallery0.jpg"),
                    require("@/assets/images/project/public/yml_gallery1.jpg"),
                    require("@/assets/images/project/public/yml_gallery2.jpg"),
                    require("@/assets/images/project/public/yml_gallery3.jpg"),
                    require("@/assets/images/project/public/yml_gallery4.jpg"),
                    require("@/assets/images/project/public/yml_gallery5.jpg"),
                    require("@/assets/images/project/public/yml_gallery6.jpg"),
                    require("@/assets/images/project/public/yml_gallery7.jpg"),
                    require("@/assets/images/project/public/yml_gallery8.jpg"),
                    require("@/assets/images/project/public/yml_gallery9.jpg"),
                    require("@/assets/images/project/public/yml_gallery10.jpg"),
                    require("@/assets/images/project/public/yml_gallery11.jpg"),
                    require("@/assets/images/project/public/yml_gallery12.jpg"),
                    require("@/assets/images/project/public/yml_gallery13.jpg"),
                    require("@/assets/images/project/public/yml_gallery14.jpg"),
                    require("@/assets/images/project/public/yml_gallery15.jpg"),
                    require("@/assets/images/project/public/yml_gallery16.jpg"),
                    require("@/assets/images/project/public/yml_gallery17.jpg"),
                    require("@/assets/images/project/public/yml_gallery18.jpg"),
                    require("@/assets/images/project/public/yml_gallery19.jpg"),
                ],
            },
            mainSelected: false,
            mainImage: [
                require("@/assets/images/project/public/yml_main0.jpg"),
                require("@/assets/images/project/public/yml_main1.jpg"),
                require("@/assets/images/project/public/yml_main2.jpg"),
            ],
            referenceSelected: true,
        },
        {
            type: "university",
            titleKr: "교원대학교",
            titleEn: "Korea National University of Education",
            date: "2018",
            image: {
                thumbnail: require("@/assets/images/project/university/knue_thumbnail.jpg"),
                detail: require("@/assets/images/project/university/knue_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/university/knue_gallery0.jpg"),
                    require("@/assets/images/project/university/knue_gallery1.jpg"),
                    require("@/assets/images/project/university/knue_gallery2.jpg"),
                    require("@/assets/images/project/university/knue_gallery3.jpg"),
                    require("@/assets/images/project/university/knue_gallery4.jpg"),
                    require("@/assets/images/project/university/knue_gallery5.jpg"),
                    require("@/assets/images/project/university/knue_gallery6.jpg"),
                    require("@/assets/images/project/university/knue_gallery7.jpg"),
                    require("@/assets/images/project/university/knue_gallery8.jpg"),
                    require("@/assets/images/project/university/knue_gallery9.jpg"),
                    require("@/assets/images/project/university/knue_gallery10.jpg"),
                    require("@/assets/images/project/university/knue_gallery11.jpg"),
                    require("@/assets/images/project/university/knue_gallery12.jpg"),
                    require("@/assets/images/project/university/knue_gallery13.jpg"),
                ],
            },
            mainSelected: false,
            mainImage: [
                require("@/assets/images/project/university/knue_main0.jpg"),
                require("@/assets/images/project/university/knue_main1.jpg"),
                require("@/assets/images/project/university/knue_main2.jpg"),
            ],
            referenceSelected: true,
        },
        {
            type: "public",
            titleKr: "하남디지털도서관",
            titleEn: "HANAM CITY DIGITAL LIBRARY",
            date: "2018",
            image: {
                thumbnail: require("@/assets/images/project/public/hcdl_thumbnail.jpg"),
                detail: require("@/assets/images/project/public/hcdl_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/public/hcdl_gallery0.jpg"),
                    require("@/assets/images/project/public/hcdl_gallery1.jpg"),
                    require("@/assets/images/project/public/hcdl_gallery2.jpg"),
                    require("@/assets/images/project/public/hcdl_gallery3.jpg"),
                    require("@/assets/images/project/public/hcdl_gallery4.jpg"),
                    require("@/assets/images/project/public/hcdl_gallery5.jpg"),
                    require("@/assets/images/project/public/hcdl_gallery6.jpg"),
                    require("@/assets/images/project/public/hcdl_gallery7.jpg"),
                ],
            },
            mainSelected: false,
            mainImage: [
                require("@/assets/images/project/public/hcdl_main0.jpg"),
                require("@/assets/images/project/public/hcdl_main1.jpg"),
            ],
            referenceSelected: true,
        },
        {
            type: "university",
            titleKr: "경북대학교",
            titleEn: "Kyungpook National University",
            date: "2018",
            image: {
                thumbnail: require("@/assets/images/project/university/knu_thumbnail.jpg"),
                detail: require("@/assets/images/project/university/knu_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/university/knu_gallery0.jpg"),
                    require("@/assets/images/project/university/knu_gallery1.jpg"),
                    require("@/assets/images/project/university/knu_gallery2.jpg"),
                    require("@/assets/images/project/university/knu_gallery3.jpg"),
                    require("@/assets/images/project/university/knu_gallery4.jpg"),
                    require("@/assets/images/project/university/knu_gallery5.jpg"),
                    require("@/assets/images/project/university/knu_gallery6.jpg"),
                    require("@/assets/images/project/university/knu_gallery7.jpg"),
                    require("@/assets/images/project/university/knu_gallery8.jpg"),
                    require("@/assets/images/project/university/knu_gallery9.jpg"),
                    require("@/assets/images/project/university/knu_gallery10.jpg"),
                    require("@/assets/images/project/university/knu_gallery11.jpg"),
                    require("@/assets/images/project/university/knu_gallery12.jpg"),
                    require("@/assets/images/project/university/knu_gallery13.jpg"),
                    require("@/assets/images/project/university/knu_gallery14.jpg"),
                    require("@/assets/images/project/university/knu_gallery15.jpg"),
                    require("@/assets/images/project/university/knu_gallery16.jpg"),
                    require("@/assets/images/project/university/knu_gallery17.jpg"),
                    require("@/assets/images/project/university/knu_gallery18.jpg"),
                ],
            },
            mainSelected: false,
            mainImage: [
                require("@/assets/images/project/university/knu_main0.jpg"),
                require("@/assets/images/project/university/knu_main1.jpg"),
                require("@/assets/images/project/university/knu_main2.jpg"),
            ],
            referenceSelected: true,
        },
        {
            type: "university",
            titleKr: "한양대학교",
            titleEn: "HANYANG UNIVERSITY",
            date: "2017",
            image: {
                thumbnail: require("@/assets/images/project/university/hyu_thumbnail.jpg"),
                detail: require("@/assets/images/project/university/hyu_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/university/hyu_gallery0.jpg"),
                    require("@/assets/images/project/university/hyu_gallery1.jpg"),
                    require("@/assets/images/project/university/hyu_gallery2.jpg"),
                    require("@/assets/images/project/university/hyu_gallery3.jpg"),
                    require("@/assets/images/project/university/hyu_gallery4.jpg"),
                    require("@/assets/images/project/university/hyu_gallery5.jpg"),
                    require("@/assets/images/project/university/hyu_gallery6.jpg"),
                    require("@/assets/images/project/university/hyu_gallery7.jpg"),
                    require("@/assets/images/project/university/hyu_gallery8.jpg"),
                    require("@/assets/images/project/university/hyu_gallery9.jpg"),
                    require("@/assets/images/project/university/hyu_gallery10.jpg"),
                    require("@/assets/images/project/university/hyu_gallery11.jpg"),
                    require("@/assets/images/project/university/hyu_gallery12.jpg"),
                    require("@/assets/images/project/university/hyu_gallery13.jpg"),
                ],
            },
            mainSelected: false,
            mainImage: [
                require("@/assets/images/project/university/hyu_main0.jpg"),
                require("@/assets/images/project/university/hyu_main1.jpg"),
                require("@/assets/images/project/university/hyu_main2.jpg"),
            ],
            referenceSelected: true,
        },
        {
            type: "university",
            titleKr: "카이스트",
            titleEn: "KAIST",
            date: "2017",
            image: {
                thumbnail: require("@/assets/images/project/university/kaist_thumbnail.jpg"),
                detail: require("@/assets/images/project/university/kaist_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/university/kaist_gallery0.jpg"),
                    require("@/assets/images/project/university/kaist_gallery1.jpg"),
                    require("@/assets/images/project/university/kaist_gallery2.jpg"),
                    require("@/assets/images/project/university/kaist_gallery3.jpg"),
                    require("@/assets/images/project/university/kaist_gallery4.jpg"),
                    require("@/assets/images/project/university/kaist_gallery5.jpg"),
                    require("@/assets/images/project/university/kaist_gallery6.jpg"),
                    require("@/assets/images/project/university/kaist_gallery7.jpg"),
                    require("@/assets/images/project/university/kaist_gallery8.jpg"),
                    require("@/assets/images/project/university/kaist_gallery9.jpg"),
                    require("@/assets/images/project/university/kaist_gallery10.jpg"),
                    require("@/assets/images/project/university/kaist_gallery11.jpg"),
                    require("@/assets/images/project/university/kaist_gallery12.jpg"),
                    require("@/assets/images/project/university/kaist_gallery13.jpg"),
                ],
            },
            mainSelected: false,
            mainImage: [
                require("@/assets/images/project/university/kaist_main0.jpg"),
                require("@/assets/images/project/university/kaist_main1.jpg"),
                require("@/assets/images/project/university/kaist_main2.jpg"),
            ],
            referenceSelected: true,
        },
        {
            type: "university",
            titleKr: "청주대학교 중앙도서관",
            titleEn: "CHEONGJU UNIVERSITY LIBRARY",
            date: "2016",
            image: {
                thumbnail: require("@/assets/images/project/university/cju_thumbnail.jpg"),
                detail: require("@/assets/images/project/university/cju_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/university/cju_gallery0.jpg"),
                    require("@/assets/images/project/university/cju_gallery1.jpg"),
                    require("@/assets/images/project/university/cju_gallery2.jpg"),
                    require("@/assets/images/project/university/cju_gallery3.jpg"),
                    require("@/assets/images/project/university/cju_gallery4.jpg"),
                    require("@/assets/images/project/university/cju_gallery5.jpg"),
                    require("@/assets/images/project/university/cju_gallery6.jpg"),
                    require("@/assets/images/project/university/cju_gallery7.jpg"),
                    require("@/assets/images/project/university/cju_gallery8.jpg"),
                    require("@/assets/images/project/university/cju_gallery9.jpg"),
                ],
            },
            mainSelected: false,
            mainImage: [
                require("@/assets/images/project/university/cju_main0.jpg"),
                require("@/assets/images/project/university/cju_main1.jpg"),
                require("@/assets/images/project/university/cju_main2.jpg"),
            ],
            referenceSelected: true,
        },
        {
            type: "university",
            titleKr: "동덕여자대학교 도서관",
            titleEn: "DONGDUK WOMEN’S UNIVERSITY LIBRARY",
            date: "2016",
            image: {
                thumbnail: require("@/assets/images/project/university/ddwu_thumbnail.jpg"),
                detail: require("@/assets/images/project/university/ddwu_detail.jpg"),
                gallery: [
                    require("@/assets/images/project/university/ddwu_gallery0.jpg"),
                    require("@/assets/images/project/university/ddwu_gallery1.jpg"),
                    require("@/assets/images/project/university/ddwu_gallery2.jpg"),
                    require("@/assets/images/project/university/ddwu_gallery3.jpg"),
                    require("@/assets/images/project/university/ddwu_gallery4.jpg"),
                    require("@/assets/images/project/university/ddwu_gallery5.jpg"),
                    require("@/assets/images/project/university/ddwu_gallery6.jpg"),
                    require("@/assets/images/project/university/ddwu_gallery7.jpg"),
                    require("@/assets/images/project/university/ddwu_gallery8.jpg"),
                    require("@/assets/images/project/university/ddwu_gallery9.jpg"),
                    require("@/assets/images/project/university/ddwu_gallery10.jpg"),
                    require("@/assets/images/project/university/ddwu_gallery11.jpg"),
                    require("@/assets/images/project/university/ddwu_gallery12.jpg"),
                    require("@/assets/images/project/university/ddwu_gallery13.jpg"),
                    require("@/assets/images/project/university/ddwu_gallery14.jpg"),
                    require("@/assets/images/project/university/ddwu_gallery15.jpg"),
                ],
            },
            mainSelected: false,
            mainImage: [
                require("@/assets/images/project/university/ddwu_main0.jpg"),
                require("@/assets/images/project/university/ddwu_main1.jpg"),
                require("@/assets/images/project/university/ddwu_main2.jpg"),
            ],
            referenceSelected: true,
        },
    ],
};
