import Vue from 'vue'
import Router from 'vue-router'
import Main from '@/pages/main/main'
import About from '@/pages/about/about'
import Works from '@/pages/works/works'
import WorksConsulting from '@/pages/works/consulting'
import WorksIct from '@/pages/works/ict'
import WorksSystem from '@/pages/works/system'
import Project from '@/pages/project/project'
import ProjectList from '@/pages/project/list'
import ProjectView from '@/pages/project/view'
import Contact from '@/pages/contact/contact'


Vue.use(Router)

const router = new Router({
  // mode: "history",
  routes: [
    {
      path: '/',
      name: 'main',
      component: Main
    },
    {
      path: '/about',
      name: 'about',
      component: About
    },
    {
      path: '/works',
      name: 'works',
      component: Works,
      children: [
        {
          path: 'consulting',
          name: 'consulting',
          component: WorksConsulting
        },
        {
          path: 'ict',
          name: 'ict',
          component: WorksIct
        },
        {
          path: 'system',
          name: 'system',
          component: WorksSystem
        },
      ]
    },
    {
      path: '/project/all',
      name: 'all',
      component: Project,
      children: [
        {
          path: 'list/:index',
          name: 'all',
          component: ProjectList,
        }
      ]
    },
    {
      path: '/project/public',
      name: 'public',
      component: Project,
      children: [
        {
          path: 'list/:index',
          name: 'public',
          component: ProjectList,
        },
        {
          path: 'view/:index',
          name: 'public',
          component: ProjectView,
        },
      ]
    },
    {
      path: '/project/etc',
      name: 'etc',
      component: Project,
      children: [
        {
          path: 'list/:index',
          name: 'etc',
          component: ProjectList,
        },
        {
          path: 'view/:index',
          name: 'etc',
          component: ProjectView,
        },
      ]
    },
    {
      path: '/project/university',
      name: 'university',
      component: Project,
      children: [
        {
          path: 'list/:index',
          name: 'university',
          component: ProjectList,
        },
        {
          path: 'view/:index',
          name: 'university',
          component: ProjectView,
        },
      ]
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact
    }
  ]
})

export default router