<template>
  <div class="consulting">
    <p class="slogon">
      DSC 는 물리적인 공간에 디지털 기기, 유무선 네트워크, 소프트웨어 등 다양한
      첨단 IT 기술과 디자인을 적용한 “디지털 복합 공간 융합” 사업으로
      <br />도서관, 전시관, 박물관, 복합 쇼핑몰 등에 적용되는 최적의
      솔루션입니다. ㈜네트콤은 전문화된 경험과 실적을 바탕 으로 고객 중심의
      최고의 서비스를 제공합니다.
    </p>
    <article class="introduce">
      <div class="wrapper">
        <ul class="introduce__list">
          <li class="introduce__item">
            <img
              src="@/assets/images/works/consulting/introduce_space.jpg"
              class="introduce__image"
              alt=""
            />
            <div class="introduce__context">
              <strong class="introduce__title">Space Design</strong>
              <p>
                학습 공간/시설 공유가 가능한 혁신적 교육환경을 제공하는 것을
                목표로 도서관 공간 모델에 대한 기초부터 설계합니다.
              </p>
              <!-- <a href="mailto:master@netcomm.co.kr" class="download">
                <i class="download__icon">
                  <img src="@/assets/images/icon/mail_white.png" alt="" />
                </i>
                <span class="download__text">컨설팅의뢰 온라인문의</span>
              </a> -->
            </div>
          </li>
          <li class="introduce__item">
            <img
              src="@/assets/images/works/consulting/introduce_interior.jpg"
              class="introduce__image"
              alt=""
            />
            <div class="introduce__context">
              <strong class="introduce__title">Interior Design</strong>
              <p>
                적용배치계획에 따른 아이템 하우징 및 인테리어 디자인으로
                도서관별 아이덴티티를 살려줍니다.
              </p>
            </div>
          </li>
          <li class="introduce__item">
            <img
              src="@/assets/images/works/consulting/introduce_furniture.jpg"
              class="introduce__image"
              alt=""
            />
            <div class="introduce__context">
              <strong class="introduce__title">Custom Furniture</strong>
              <p>
                첨단 IT 기술과 디자인을 적용한 맞춤가구 제작하여 SMART LIBRARY를
                구현합니다.
              </p>
              <!-- <a
								:href="`${publicPath}file/furniture_list.pdf`"
								class="download"
								download="가구 제품 리스트">
								<i class="download__icon">
									<img src="@/assets/images/icon/download_white.png" alt="" />
								</i>
								<span class="download__text">가구제품리스트 다운로드</span>
							</a> -->
            </div>
          </li>
        </ul>
      </div>
    </article>
    <article class="reference">
      <div class="wrapper">
        <h3>Reference</h3>
        <div class="reference__list swiper-container">
          <div class="swiper-wrapper">
            <div
              v-for="reference in referenceData"
              :key="reference.key"
              class="reference__item swiper-slide"
            >
              <a
                :href="`/#/project/${reference.type}/view/${reference.index}`"
                class="reference__thumbnail thumbnail"
              >
                <img :src="reference.image.thumbnail" alt="" />
                <p class="reference__title">{{ reference.titleKr }}</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import projectData from "../project/project-data";
import Swiper, { EffectFade, Autoplay, Scrollbar } from "swiper";
import "swiper/swiper-bundle.css";
Swiper.use([EffectFade, Autoplay, Scrollbar]);

var referenceSlider;
function referenceSwiper() {
  if (referenceSlider == null || referenceSlider.destroyed) {
    referenceSlider = new Swiper(".reference__list", {
      observer: true,
      observeParents: true,
      slidesPerView: 2,
      slidesPerGroup: 2,
      speed: 1000,
      // autoplay: {
      // 	delay: 2500,
      // },
    });
  }
}

export default {
  name: "Consulting",
  data: () => projectData,
  computed: {
    referenceData: function() {
      var data;
      data = this.projectList.filter((item) => item.referenceSelected === true);
      this.filterData(data, "public");
      this.filterData(data, "university");
      this.filterData(data, "etc");
      return data;
    },
  },
  mounted() {
    if (this.isMobile()) {
      referenceSwiper();
    }
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize(event) {
      if (this.isMobile()) {
        referenceSwiper();
      } else {
        if (referenceSlider != null) referenceSlider.destroy(true, true);
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
