<template>
	<div class="system">
		<p class="slogon">
			정보화 사회의 핵심역량으로 인식되는 정보와 지식을 보다 효율적이고
			선도적으로 서비스할 수 있는 도서관 정보화 시스템을 구축합니다. 지속 가능한
			도서관 지식 활용 기반을 조성하기 위하여 <br />이용자 중심의 지식정보
			서비스 구축, 유비쿼터스 기술 기반의 U-도서관 구축 등 정보화 준비단계에서
			부터 완료까지 종합적으로 기획 및 설계하여 최고의 서비스를 제공합니다.
		</p>
		<article class="banner">
			<div class="wrapper">
				<h3 class="hidden"></h3>
				<div class="banner__slider swiper-container">
					<div class="swiper-wrapper">
						<div
							v-for="(banner, bannerIndex) in bannerList"
							:key="banner.key"
							:style="{
								'background-image':
									'url(' +
									require(`@/assets/images/works/system/banner_image${bannerIndex}.jpg`) +
									')',
							}"
							class="background swiper-slide"
						>
							<div class="banner__context">
								<div class="banner__number">
									{{
										9 > bannerIndex ? "0" + (bannerIndex + 1) : bannerIndex + 1
									}}
								</div>
								<strong class="banner__title">{{ banner.title }}</strong>
								<ul class="banner__hashtage">
									<li v-for="hasTag in banner.hasTag" :key="hasTag.key">
										{{ hasTag.name }}
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="swiper-pagination"></div>
					<button
						type="button"
						class="swiper-navigation swiper-navigation-prev"
					>
						<img src="@/assets/images/icon/slide_prev_white.png" alt="" />
					</button>
					<button
						type="button"
						class="swiper-navigation swiper-navigation-next"
					>
						<img src="@/assets/images/icon/slide_next_white.png" alt="" />
					</button>
				</div>
			</div>
		</article>
		<article class="build">
			<div class="wrapper">
				<h3>구축리스트</h3>
				<Tabs
					:tabs="buildList"
					:currentTab="buildTabCurrent"
					:wrapper-class="'default-tabs'"
					:tab-class="'default-tabs__item'"
					:tab-active-class="'default-tabs__item_active'"
					:line-class="'default-tabs__active-line'"
					@onClick="buildTabClick"
				/>
				<div class="cooperation__tabpanel tabpanel">
					<div
						v-for="(build, buildIndex) in buildList"
						:key="build.key"
						:class="{ active: buildTabCurrent === `buildTab${buildIndex}` }"
					>
						<transition name="fade-delay">
							<div
								v-if="buildTabCurrent === `buildTab${buildIndex}`"
								class="build__contents"
							>
								<h4 class="build__year">{{ build.title }}</h4>
								<div
									v-for="buildContext in build.context"
									:key="buildContext.key"
									class="build__context"
								>
									<strong class="build__month">{{ buildContext.month }}</strong>
									<ul class="build__text">
										<li
											v-for="buildTextList in buildContext.textList"
											:key="buildTextList.key"
										>
											{{ buildTextList.text }}
										</li>
									</ul>
								</div>
							</div>
						</transition>
					</div>
				</div>
			</div>
		</article>
		<article class="cooperation">
			<div class="wrapper">
				<h3>협력사</h3>
				<Tabs
					:tabs="cooperationList"
					:currentTab="cooperationTabCurrent"
					:wrapper-class="'default-tabs'"
					:tab-class="'default-tabs__item'"
					:tab-active-class="'default-tabs__item_active'"
					:line-class="'default-tabs__active-line'"
					@onClick="cooperationTabClick"
				/>
				<div class="cooperation__tabpanel tabpanel">
					<div
						v-for="(cooperation, cooperationIndex) in cooperationList"
						:key="cooperation.key"
						:class="{
							active:
								cooperationTabCurrent === `cooperationTab${cooperationIndex}`,
						}"
					>
						<transition name="fade-delay" mode="">
							<div
								v-if="
									cooperationTabCurrent === `cooperationTab${cooperationIndex}`
								"
								class="cooperation__slider swiper-container"
							>
								<div class="swiper-wrapper">
									<div
										v-for="(length, lengthIndex) in cooperation.length"
										:key="length.key"
										class="cooperation__item swiper-slide"
									>
										<img
											:src="
												require(`@/assets/images/works/system/cooperative${cooperationIndex}_logo${lengthIndex}.jpg`)
											"
											alt=""
										/>
									</div>
								</div>
								<div class="swiper-pagination"></div>
							</div>
						</transition>
					</div>
				</div>
			</div>
		</article>
	</div>
</template>

<script>
import systemData from "./system-data";
import Tabs from "vue-tabs-with-active-line";
import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
Swiper.use([Navigation, Pagination]);

function cooperationSlider() {
	if (document.querySelectorAll(".active .cooperation__item").length > 24) {
		new Swiper(".active .cooperation__slider", {
			observer: true,
			observeParents: true,
			speed: 1000,
			slidesPerView: 2,
			slidesPerColumn: 4,
			slidesPerColumnFill: "row",
			slidesPerGroup: 2,
			breakpoints: {
				767: {
					slidesPerView: 3,
					slidesPerColumn: 4,
					slidesPerGroup: 3,
				},
				1025: {
					slidesPerView: 4,
					slidesPerColumn: 4,
					slidesPerGroup: 4,
				},
				1367: {
					slidesPerView: 6,
					slidesPerColumn: 4,
					slidesPerGroup: 6,
				},
			},
			pagination: {
				el: ".cooperation .swiper-pagination",
				clickable: true,
			},
		});
	}
}

export default {
	name: "System",
	components: {
		Tabs,
	},
	data: () => systemData,
	methods: {
		buildTabClick(newTab) {
			this.buildTabCurrent = newTab;
		},
		cooperationTabClick(newTab) {
			this.cooperationTabCurrent = newTab;
		},
	},
	mounted() {
		new Swiper(".banner__slider", {
			observer: true,
			observeParents: true,
			loop: true,
			simulateTouch: false,
			speed: 1000,
			navigation: {
				nextEl: `.banner__slider .swiper-navigation-next`,
				prevEl: `.banner__slider .swiper-navigation-prev`,
			},
			pagination: {
				el: `.banner__slider .swiper-pagination`,
				clickable: true,
			},
			on: {
				init: function () {},
			},
		});
		cooperationSlider();
	},
	updated() {
		cooperationSlider();
	},
};
</script>

<style lang="scss">
</style>