<template>
	<ul class="tablist">
		<li
			v-for="tab in tabs"
			:key="tab.key"
			:class="{
				active:
					$route.matched[0].path.split('/').length > 2
						? !tab.link.indexOf($route.matched[0].path)
						: tab.link == $route.path,
			}"
			class="tab"
		>
			<router-link :to="tab.link">
				{{ tab.title }}
			</router-link>
		</li>
	</ul>
</template>

<script>
export default {
	name: "Tabs",
	props: {
		tabs: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
	},
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/scss/_mixin";

.tablist {
    display: flex;
}

.tab {
    flex: 1;
    &+& {
        margin-left: 0.9375rem;
        @include respond("mobile") {
            margin-left: 0.15rem;
        }
    }
    &.active {
        a {
            border: none;
            background-color: #385cae;
            color: #fff;
        }
    }
    a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.125rem;
        border: solid 0.0625rem #cccccc;
    }
}
</style>
