export default {
    // 연혁
    historyList: [
        {
            year: "2023",
            contenxt: [
                {
                    month: "2월",
                    text: "인천대학교 제2도서관 ICT시스템 구축",
                },
                {
                    month: "",
                    text: "한국체육대학교 메타버스 학술정보원 관리운영시스템 구축",
                },
            ],
        },
        {
            year: "2022",
            contenxt: [
                {
                    month: "8월",
                    text: "강원대학교 도서관 IOT 운영시스템 구축",
                },
                {
                    month: "5월",
                    text: "KNU미래도서관 관리운영시스템 구축",
                },
                {
                    month: "3월",
                    text: "국회부산도서관 열람공간 ICT 시스템 구축",
                },
            ],
        },
        {
            year: "2021",
            contenxt: [
                {
                    month: "12월",
                    text: "강원대학교 신축도서관 미디어커먼스 시스템 구축",
                },
                {
                    month: "11월",
                    text: "세종시립도서관 ICT 통합정보시스템 구축",
                },
                {
                    month: "5월",
                    text: "육군사관학교 스마트도서관 관리운영시스템 구축",
                },
            ],
        },
        {
            year: "2020",
            contenxt: [
                {
                    month: "11월",
                    text: "충남대학교 신축도서관 관리운영시스템 구축",
                },
                {
                    month: "10월",
                    text: "서초청소년도서관 ICT시스템 구축 용역",
                },
                {
                    month: "9월",
                    text: "안동시립중앙도서관 ICT 통합관리시스템 구축",
                },
                // {
                //   month: "3월",
                //   text: "품질경영시스템 인증 ISO 9001 / 환경경영시스템 인증 ISO 14001",
                // },
                {
                    month: "2월",
                    text: "삼성전자 B2B대리점 등록",
                },
            ],
        },
        {
            year: "2019",
            contenxt: [
                {
                    month: "12월",
                    text: "사명 변경 ㈜네트콤",
                },
                {
                    month: "9월",
                    text: "여수시청 감사패 수여 (이순신도서관 IOT정보화시스템 구축 사업)",
                },
            ],
        },
        {
            year: "2017",
            contenxt: [
                {
                    month: "10월",
                    text: "KAIST 학술문화관 IT시스템 구축",
                },
            ],
        },
        {
            year: "2016",
            contenxt: [
                {
                    month: "9월",
                    text: "청주대학교 중앙도서관 리모델링 통합시스템 구축",
                },
                {
                    month: "5월",
                    text: "동덕여자대학교 감사패 수여 (동덕여자대학교 백주년기념관  DSC 구축 사업)",
                },
            ],
        },
        {
            year: "2015",
            contenxt: [
                {
                    month: "10월",
                    text: "특허취득 – 도서 서지 정보 정보오류 검출방법, 오류검출장치 및 오류검출 시스템",
                },
            ],
        },
        {
            year: "2014",
            contenxt: [
                {
                    month: "6월",
                    text: "특허취득 – 태그의 사용자 메모리 영역을 이용한 게이트 통과방법 및 게이트 통과시스템",
                },
            ],
        },
        {
            year: "2013",
            contenxt: [
                {
                    month: "12월",
                    text: "원광대학교 감사패 수여(중앙도서관 U-환경구축)",
                },
                {
                    month: "6월",
                    text: "스마트 검색엔진 한국저작권위원회 프로그램 등록",
                },
                {
                    month: "3월",
                    text: "특허 취득 – 자가인출이 가능한 물품연출방법 및 물품인출 시스템",
                },
            ],
        },
        {
            year: "2012",
            contenxt: [
                {
                    month: "9월",
                    text: "특허취득 – 개인 휴대용 단말기를 이용한 도서 탐색 및 대출방법",
                },
                {
                    month: "3월",
                    text: "제48회 모범납세자 국세청장상 표창",
                },
            ],
        },
        {
            year: "2011",
            contenxt: [
                {
                    month: "12월",
                    text: "특허 취득 – 도서 분류 방법 및 도서 분류 시스템,  “자동 도서분류를 이용한 도서구매 방법 및 도서구매시스템”",
                },
                {
                    month: "3월",
                    text: "㈜비알네트콤 부설 연구소 설립 (제2011110608호)",
                },
            ],
        },
        {
            year: "2010",
            contenxt: [
                {
                    month: "12월",
                    text: "㈜비알네트콤 INNO-Biz 획득, 벤처기업 등록",
                },
                {
                    month: "",
                    text: "서울시 교육청산하 22개 평생학습관 및 도서관 통합전자도서관 사업구축",
                },
            ],
        },
        {
            year: "2008",
            contenxt: [
                {
                    month: "9월",
                    text: "특허 취득 – “좌석관리시스템 및 그 운영방법”",
                },
                {
                    month: "5월",
                    text: "Good Software 인증 – 알리미시스템",
                },
                {
                    month: "1월",
                    text: "제1회 도서관 정보화 세미나실시-(사)한국도서관협회 공동",
                },
            ],
        },
        {
            year: "2007",
            contenxt: [
                {
                    month: "4월",
                    text: "노원구정보도서관 공로패 수여",
                },
            ],
        },
        {
            year: "2006",
            contenxt: [
                {
                    month: "10월",
                    text: "은평구구청장 표창(일반 열람실 좌석관리시스템 구축)",
                },
                {
                    month: "6월",
                    text: "정보통신공사업 등록",
                },
            ],
        },
        {
            year: "2005",
            contenxt: [
                {
                    month: "12월",
                    text: "'도서관정보화부문' 정보통신부장관상 수상",
                },
            ],
        },
        {
            year: "2002",
            contenxt: [
                {
                    month: "11월",
                    text: "전자신문사 신소프트웨어 대상수상",
                },
            ],
        },
        {
            year: "1998",
            contenxt: [
                {
                    month: "11월",
                    text: "정보통신부 IT사업 지원업체 지정 (복제방지솔루션)",
                },
            ],
        },
        {
            year: "1996",
            contenxt: [
                {
                    month: "10월",
                    text: "(주)비알네트콤 설립",
                },
            ],
        },
    ],
    // 특허 및 인증
    patentList: [
        { name: "좌석관리시스템 및 그 운영방법" },
        { name: "자동 도서 분류를 이용한 도서 구매 방법 및 도서구매 시스템" },
        { name: "도서분류 방법 및 도서 분류 시스템" },
        { name: "개인 휴대용 단말기를 이용한 도서탐색 및 대출방법" },
        { name: "자가 인출이 가능한 물품 인출 방법 및 물품 인출 시스템" },
        {
            name:
                "태그의 사용자 메모리 영역을 이용한 게이트통과방법 및 게이트통과시스템",
        },
        { name: "서지정보 오류검출 시스템" },
        { name: "도서관 영상정보 솔루션 GS인증" },
        { name: "검색엔진 프로그램 등록" },
        // { name: "ISO 9001 품질경영시스템 인증" },
        // { name: "ISO 14001 환경경영시스템 인증" },
    ],
    // 면허 및 수상
    licenseList: [
        { name: "소프트웨어사업자 신고서" },
        { name: "정보통신공사업등록증" },
        { name: "소프트웨어 직접생산증명서" },
        { name: "디지털이노베이션 대상 정보통신부" },
        { name: "은평구청장 표창 은평구청" },
        { name: "감사패 사회복지법인 인덕원" },
        { name: "모범납세자상 / 국세청장" },
    ],
};
