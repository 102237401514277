export default {
		// 배너
		bannerList: [
			{
				title: "정보화컨설팅",
				hasTag: [
					{ name: "도서관 정보화 진단" },
					{ name: "도서관 정보화 전략" },
					{ name: "도서관 정보화 계획" },
				],
			},
			{
				title: "회원 및 도서 DB통합",
				hasTag: [
					{ name: "DB 통합 계획 수립" },
					{ name: "DB 변환 및 이전" },
					{ name: "지역도서관 데이터 통합" },
					{ name: "지역 상호대차 서비스" },
					{ name: "지역도서관 연계 방안 수립" },
				],
			},
			{
				title: "특성화 컨설팅",
				hasTag: [
					{ name: "멀티미디어 시스템" },
					{ name: "U-라운지 시스템" },
					{ name: "특성화 콘텐츠 공급" },
				],
			},
			{
				title: "도서관리 솔루션",
				hasTag: [
					{ name: "도서관 통합솔루션" },
					{ name: "검색엔진 시스템" },
					{ name: "모바일앱 솔루션" },
					{ name: "서지저장 시스템" },
					{ name: "수서지원 시스템" },
				],
			},
			{
				title: "인프라 구축",
				hasTag: [
					{ name: "RFID 시스템 구축" },
					{ name: "서버시스템구축" },
					{ name: "보안 (서버/DB,접근제어)" },
					{ name: "백업 시스템 구축" },
					{ name: "시스템 이중화" },
					{ name: "개인정보보호" },
				],
			},
		],
		// 구축리스트
		buildTabCurrent: "buildTab0",
		buildList: [
			// {
			// 	title: "2023",
			// 	value: "buildTab0",
			// 	context: [
			// 		{
			// 			month: "4월",
			// 			textList: [
			// 				{ text: "전포어울더울 작은도서관 ICT시스템 구매·설치" },
			// 			],
			// 		},
			// 		{
			// 			month: "3월",
			// 			textList: [
			// 				{ text: "부산대학교 미리내열람실 In(人)novation Park 아카데미아 구축" },
			// 			],
			// 		},
			// 		{
			// 			month: "2월",
			// 			textList: [
			// 				{ text: "경상국립대학교 도서관 스마트스터디룸 관리운영시스템 구축" },
			// 				{ text: "부산대학교 새벽벌도서관 2층 24시 열람공간 구축" },
			// 				{ text: "인천대학교 제2도서관 ICT시스템 구축" },
			// 			],
			// 		},
			// 		{
			// 			month: "1월",
			// 			textList: [
			// 				{ text: "한국체육대학교 메타버스 학술정보원 관리운영시스템 구축" },
			// 			],
			// 		},
			// 	],
			// },
			// {
			// 	title: "2022",
			// 	value: "buildTab1",
			// 	context: [
			// 		{
			// 			month: "11월",
			// 			textList: [
			// 				{ text: "책테마파크도서관 ICT시스템 구축" },
			// 			],
			// 		},
			// 		{
			// 			month: "9월",
			// 			textList: [
			// 				{ text: "남산도서관 디지털라운지 ICT 장비 구축" },
			// 			],
			// 		},
			// 		{
			// 			month: "8월",
			// 			textList: [
			// 				{ text: "강원대학교 도서관  IOT 운영시스템 구축" },
			// 			],
			// 		},
			// 		{
			// 			month: "7월",
			// 			textList: [
			// 				{ text: "회화나무 작은도서관 디지털 특화공간 및 콘텐츠 구축용역" },
			// 			],
			// 		},
			// 		{
			// 			month: "6월",
			// 			textList: [
			// 				{ text: "광주광역시 북구 신용 복합공공도서관 [ICT, RFID] 시스템 구축 용역" },
			// 				{ text: "광주광역시 북구 중흥 복합공공도서관 [ICT, RFID] 시스템 구축 용역" },
			// 			],
			// 		},
			// 		{
			// 			month: "3월",
			// 			textList: [
			// 				{ text: "2022년도 국회부산도서관 열람공간 ICT 시스템 구입 및 설치 사업" },
			// 			],
			// 		},
			// 		{
			// 			month: "1월",
			// 			textList: [
			// 				{ text: "흑석동 복합도서관 ICT 시스템 구축(까망돌도서관)" },
			// 			],
			// 		},
			// 	],
			// },
			// {
			// 	title: "2021",
			// 	value: "buildTab2",
			// 	context: [
			// 		{
			// 			month: "11월",
			// 			textList: [
			// 				{ text: "세종시립도서관 ICT 통합정보시스템 구축" },
			// 			],
			// 		},
			// 		{
			// 			month: "8월",
			// 			textList: [
			// 				{ text: "여수시립현암도서관 스마트 도서관 구축 용역" },
			// 			],
			// 		},
			// 		{
			// 			month: "4월",
			// 			textList: [
			// 				{ text: "육군사관학교스마트도서관 관리운영시스템" },
			// 			],
			// 		},
			// 	],
			// },
			{
				title: "2020",
				value: "buildTab0",
				context: [
					{
						month: "1월",
						textList: [
							{ text: "대구광역시 도서관 통합시스템 3차 구축 사업" },
							{ text: "KAIST 학술문화관 IT시스템 유지보수 사업" },
						],
					},
					{
						month: "6월",
						textList: [
							{ text: "충남대학교 신축 도서관 관리운영시스템  구축 사업" },
							{ text: "안동시 옥동도서관 ICT구축 사업" },
							{ text: "부산도서관 정보서비스 운영장비 구축" },
						],
					},
				],
			},
			{
				title: "2019",
				value: "buildTab1",
				context: [
					{
						month: "1월",
						textList: [
							{ text: "의왕시평생학습관 홈페이지 유지보수 용역" },
							{ text: "충청남도교육청 충청남도서부평생학습관 2019년 모바일앱 유지보수 용역" },
						],
					},
					{
						month: "2월",
						textList: [
							{ text: "한국교원대학교 신축도서관 IT통합시스템 구축 사업" },
							{ text: "양재도서관 ICT 시스템 구축" },
							{ text: "창신3동 공동이용시설 도서관 실내공간조성 및 시설구축 용역" },
						],
					},
					{
						month: "3월",
						textList: [
							{ text: "국기원 2018년 온라인 플랫폼 서비스 기반 및 인적 DB구축" },
							{ text: "한국교통대 도서관 열람시스템 3종 유지보수" },
							{ text: "하남 위례도서관 컨설팅" },
						],
					},
					{
						month: "4월",
						textList: [{ text: "고려대학교 SK미래관 ICT컨설팅" }],
					},
					{
						month: "5월",
						textList: [
							{ text: "2019년 동작구 통합도서관 도서관리시스템 유지보수 용역 계약" },
							{ text: "경북도서관 정보시스템 구축 사업" },
							{ text: "KAIST 학술문화관 IT시스템 유지보수 사업" },
						],
					},
					{
						month: "6월",
						textList: [
							{ text: "장애인 대체자료 제작을 위한 수식의 음성변환 규칙 제정 및 서비스 구현(1차)" },
						],
					},
					{
						month: "7월",
						textList: [
							{ text: "국립중앙도서관 디지털도서관 정보광장시스템 재구축" },
							{ text: "여수 이순신도서관 라키비움 ICT시스템 구축사업" },
							{ text: "강릉원주대학교 원주캠퍼스 도서관 리모델링 및 스마트시스템 구축 설계용역" },
							{ text: "경북대학교 상주캠퍼스 도서관 리모델링 및 스마트시스템 구축 설계용역" },
						],
					},
					{
						month: "8월",
						textList: [
							{ text: "성동구 도서관 통합자료관리시스템 구축 사업" },
							{ text: "부산도서관 통합 웹서비스 플랫폼 구축(2단계)용역" },
							{ text: "동서대학교 민석도서관 설계 컨설팅 사업" },
						],
					},
					{
						month: "9월",
						textList: [
							{ text: "충남교육청 도서관 통합시스템 구축 사업" },
							{ text: "대구광역시 도서관 통합시스템 구축 사업" },
							{ text: "광주광역시 통합전자도서관 구축 사업" },
							{ text: "이순신도서관 IOT정보화시스템 구축 사업" },
							{ text: "한국복지대학교 AI학습실 구축 사업" },
						],
					},
					{
						month: "10월",
						textList: [
							{ text: "하남시 미사도서관 ICT시스템 구축" },
							{ text: "헌법재판소 증축도서관 물품구매 사업" },
							{ text: "충청남도교육청 도서관 통합정보시스템 구축" },
						],
					},
					{
						month: "11월",
						textList: [
							{ text: "서초구립반포도서관 통합유지보수 및 노후시스템 추구" },
						],
					},
					{
						month: "12월",
						textList: [
							{ text: "코이카 IBS 탄자니아 세렝게티 미디어센터 활성화사업" },
						],
					},
				],
			},
			{
				title: "2018",
				value: "buildTab2",
				context: [
					{
						month: "1월",
						textList: [
							{ text: "2018년 서울도서관 정보화인프라 통합유지관리 용역" },
						],
					},
					{
						month: "2월",
						textList: [
							{ text: "서울시 서남병원 문제메세지서비스(SMS, LMS)시스템 사용" },
						],
					},
					{
						month: "3월",
						textList: [
							{ text: "성남시 2018년 도서관 정보시스템 통합 유지관리 용역" },
							{ text: "한국교통대학교 디지털 정보센터 시스템 유지보수" },
						],
					},
					{
						month: "4월",
						textList: [
							{ text: "경북대학교 중앙도서관 공간재배치 및 스마트시스템 구축 설계 용역" },
						],
					},
					{
						month: "6월",
						textList: [
							{ text: "한국교육학술정보원 학교도서관 서지목록 통합 및 통계관리 기능개선" },
						],
					},
					{
						month: "7월",
						textList: [
							{ text: "부산도서관 통합 웹서비스 플랫폼 구축(1단계)용역" },
						],
					},
					{
						month: "8월",
						textList: [
							{ text: "양천구 통합도서관리시스템 구축 사업" },
							{ text: "의정부시청소년육성재단 회원통합관리시스템 재구축 용역" },
						],
					},
					{
						month: "9월",
						textList: [
							{ text: "안동시립도서관 스마트도서관 구축" },
							{ text: "2018년도 독서교육종합지원시스템 응용SW유지관리" },
							{ text: "어린이청소년국학도서관 ICT장비 구매" },
							{ text: "한국교원대학교 신축도서관 IT통합시스템 구축 사업" },
							{ text: "하남시 디지털도서관 ICT통합관리 시스템 구축" },
						],
					},
					{
						month: "11월",
						textList: [
							{ text: "국립과천과학관 자연사관 지구관측시스템 SOS프로젝터 구매" },
							{ text: "내를건너서숲으로도서관 정보화시스템 구축 용역" },
						],
					},
					{
						month: "12월",
						textList: [
							{ text: "2019년 서울도서관 정보화인프라 통합유지관리 용역" },
							{ text: "서울시교육청 2019년 도서관 정보화시스템 통합유지보수" },
							{ text: "2019년 책읽는 도시 의정부 홈페이지 시스템 유지관리 용역" },
						],
					},
				],
			},
			{
				title: "2017",
				value: "buildTab3",
				context: [
					{
						month: "1월",
						textList: [
							{ text: "코리아 에이드 사업 아프리카 3국 의료기자재 공급" },
							{ text: "한-아세안 사이버대학 설립 추진을 위한 CLMV 역량강화" },
							{ text: "2017년 서울도서관 정보화인프라 통합유지관리 용역" },
							{ text: "도서관 통합시스템(자료관리, 홈페이지, 전자도서관) 구축" },
						],
					},
					{
						month: "2월",
						textList: [
							{ text: "2017년 시립도서관 홈페이지 유지 보수 시행" },
							{ text: "2017년 고문헌 검색시스템 유지보수 계약" },
						],
					},
					{
						month: "3월",
						textList: [{ text: "세렝게티 홍보관 개선사업_추가계약품의(2차)" }],
					},
					{
						month: "4월",
						textList: [
							{ text: "국사편찬위원회 장서 RFID 태깅 용역 계약(주요03, 채명만)" },
						],
					},
					{
						month: "5월",
						textList: [
							{ text: "자기주도형 휴대용 생활환경 안전진단 키트 및 앱기반 서비스시스템(2~5차년)" },
						],
					},
					{
						month: "6월",
						textList: [
							{ text: "한양대학교 백남학술정보관 1층 라운지 디자인가구 제작" },
						],
					},
					{
						month: "7월",
						textList: [
							{ text: "독서교육종합지원시스템 노후전산장비 교체사업" },
							{ text: "경상남도 대표도서관 정보시스템 구축사업(1단계)" },
							{ text: "포항시립도서관 스마트무인도서관 구축" },
							{ text: "2017년도 제1차 서울시교육청 전자도서관 신간자료(콘텐츠 분야) 구입" },
						],
					},
					{
						month: "8월",
						textList: [
							{ text: "서울특별시교육청 도서관·평생학습관 홈페이지 전면개편 사업" },
							{ text: "인천광역시교육청 공공도서관 정보시스템 통합 구축" },
							{ text: "한국전력공사 스마트도서관 리모델링 구축 사업" },
						],
					},
					{
						month: "9월",
						textList: [
							{ text: "2017년도 독서교육 중앙서비스 운영 및 시스템 응용SW 유지관리 사업" },
						],
					},
					{
						month: "10월",
						textList: [
							{ text: "KAIST 학술문화관 IT시스템 구축" },
							{ text: "세렝게티 홍보관 개선사업_추가계약품의(3차)" },
						],
					},
					{
						month: "12월",
						textList: [
							{ text: "2018년 독서교육종합지원시스템 위탁관리용역" },
							{ text: "르완다 공무원 행정역량강화사업 시스템개발 및 기자재공급 용역업체 계약(추가계약2)" },
							{ text: "전라북도교육청 공공도서관 통합시스템 구축" },
							{ text: "도서관통합정보시스템 서비스 추가 도입 사업" },
							{ text: "2018년 도서관 정보시스템 통합 유지관리 용역" },
							{ text: "동작구 도서관 도서관리시스템 등 재구축 사업 용역(긴급)" },
							{ text: "2018년 모바일앱 시스템 유지보수 계약" },
							{ text: "2018년 책 읽는 도시, 의정부 통합 시스템 유지관리" },
						],
					},
				],
			},
			{
				title: "2016",
				value: "buildTab4",
				context: [
					{
						month: "1월",
						textList: [
							{ text: "2016년도 교육제증명 무인민원발급서비스 운영 연장" },
							{ text: "탄자니아 세렝게티 국립공원 홍보관 개선사업 사업수행용역" },
							{ text: "2016년 정보통신시스템 통합유지보수 용역" },
						],
					},
					{
						month: "4월",
						textList: [
							{ text: "서울예술대학교 예술정보센터 아카이브랩 구축 사업" },
							{ text: "국회도서관 의원열람실 조성 사업" },
						],
					},
					{
						month: "5월",
						textList: [
							{ text: "동덕여자대학교 백주년기념관  DSC 구축 사업" },
							{ text: "2016년 독서학습지도 멘토링 위탁용역" },
						],
					},
					{
						month: "7월",
						textList: [
							{ text: "자기주도형 휴대용 생활환경 안전진단 키트 및 앱기반 서비스시스템(1차년)" },
							{ text: "2016년도 제1차 서울시교육청 전자도서관 신간자료(콘텐츠 분야) 구입" },
						],
					},
					{
						month: "8월",
						textList: [
							{ text: "의왕시평생학습관 홈페이지 유지보수" },
							{ text: "2016년 중천철학도서관 알리미시스템 유지보수 용역" },
						],
					},
					{
						month: "9월",
						textList: [
							{ text: "탄자니아 세렝게티 국립공원 홍보관 개선사업_추가계약(1차)" },
							{ text: "청주대학교 중앙도서관 리모델링 통합시스템 구축" },
							{ text: "경상북도교육청 도서관 통합정보시스템 구축 계약 요청" },
						],
					},
					{
						month: "10월",
						textList: [
							{ text: "2016년 독서교육 중앙서비스운영 및 시스템 응용 SW 유지관리" },
							{ text: "능평도서관 좌석관리시스템 구축" },
						],
					},
					{
						month: "11월",
						textList: [
							{ text: "2016년도 제2차 서울시교육청 전자도서관 신간자료(콘텐츠 분야) 구입" },
							{ text: "도서관 통계 프로그램 구축 용역" },
							{ text: "도서관 통합 자료관리시스템 노후 인프라 교체(긴급)" },
						],
					},
					{
						month: "12월",
						textList: [
							{ text: "르완다 공무원 행정역량강화사업 시스템개발 및 기자재공급 용역업체" },
							{ text: "2017년 홈페이지유비보수" },
							{ text: "한국복지대학교 학술정보관 DSC구축 사업" },
							{ text: "보령제약그룹 디지털 중보관 컨텐츠 구축" },
							{ text: "경상남도교육청 전자도서관 온라인콘텐츠(이러닝강좌 등) 구입" },
							{ text: "의왕시평생학습관 홈페이지 유지보수 용역" },
							{ text: "2017년도 서부평생학습관 모바일앱 시스템 유지보수" },
							{ text: "2017년 여주시 평생학습센터 정보시스템 유지관리 용역" },
							{ text: "2017년 책 읽는 도시 의정부 통합 시스템 유지보수 계약" },
						],
					},
				],
			},
			{
				title: "2015",
				value: "buildTab5",
				context: [
					{
						month: "1월",
						textList: [
							{ text: "2015년 교육제증명무인민원발급시스템 유지보수 및 서비스운영 연장" },
							{ text: "중앙도서관 출입관리시스템 구축" },
							{ text: "장로회신학대학교 도서관 윈도우 열람석 남품" },
						],
					},
					{
						month: "2월",
						textList: [
							{ text: "2015년 통합학사정보관리사업 DB이관 및 운영유지보수" },
							{ text: "2015년 정보통신시스템 통합유지보수 용역" },
							{ text: "2015년 도서관 전산시스템 유지보수 용역" },
							{ text: "4th Creative Party Studio 영상편집 장비 납품" },
							{ text: "전북 119 안전체험관 프로젝터용 램프 납품" },
							{ text: "한국교통대학교 도서관 시설물 설치" },
							{ text: "고흥 문예회관 영상관 프로젝터 장비 납품" },
						],
					},
					{
						month: "3월",
						textList: [
							{ text: "2015년 여주시 평생학습센터 정보시스템 유지관리" },
							{ text: "담양 대나무박람회 영상실 영상콘트롤러 납품" },
							{ text: "호텔더원 영상/음향 시스템 납품" },
						],
					},
					{
						month: "5월",
						textList: [
							{ text: "의왕시 평생학습관 홈페이지 구축" },
							{ text: "연동교회 크리스티 프로젝터 납품" },
						],
					},
					{
						month: "6월",
						textList: [
							{ text: "2015년 교육제증명 무인민원발급시스템 유지보수 및 서비스" },
							{ text: "2015년 독서학습지도 멘토링 위탁용역" },
							{ text: "전자책 접근성 인증 및 관리시스템 연구 개발 사업" },
							{ text: "2015년도 소장자료 RFID 시스템 구축" },
							{ text: "정보보안 유지 운영 및 고도화를 위한 보안장비 구매" },
						],
					},
					{
						month: "7월",
						textList: [
							{ text: "한국체육산업개발 홈페이지 리뉴얼 사업" },
							{ text: "스포츠센터 홈페이지 리뉴얼 사업" },
							{ text: "책읽는도시 의정부 통합 시스템 유지보수" },
							{ text: "대구시 남구 청소년창작센터 영상시스템 구축" },
						],
					},
					{
						month: "8월",
						textList: [
							{ text: "2015년 제1차 서울시교육청 전자도서관 신간자료(컨텐츠 분야) 구입" },
							{ text: "충청남도 도립도서관 정보화전략계획(ISP) 수립 연구용역" },
						],
					},
					{
						month: "9월",
						textList: [
							{ text: "구립은평뉴타운 도서관 정보화시스템 구축용역" },
							{ text: "2015년도 경상남도교육청 통합홈페이지 구축 용역" },
						],
					},
					{
						month: "10월",
						textList: [
							{ text: "서울시교육청 도서관 홈페이지 및 평생학습포털 기능개선 용역" },
							{ text: "은평뉴타운 도서관 업무용 PC 구매" },
							{ text: "탄자니아 ID데이터센터 사용 SW공급" },
							{ text: "IBM IFC신사옥 디지털솔루션 구축" },
						],
					},
					{
						month: "11월",
						textList: [
							{ text: "2015년 독서교육중앙서비스 운영" },
							{ text: "구산동 도서관마을 정보화시스템 구축용역" },
							{ text: "원주시립중앙도서관 도서관리시스템 구축" },
						],
					},
					{
						month: "12월",
						textList: [
							{ text: "2016년 용산구 인터넷방송 홈페이지 및 모바일관련 유지관리 용역" },
							{ text: "2015년도 제2차 서울시교육청 전자도서관 신간자료(콘텐츠 분야) 구입" },
							{ text: "경상남도교육청 공공도서관 정보시스템 통합 구축 용역" },
							{ text: "2015년 서울과학기술대학교 비도서(전자책) 구매 재공고" },
							{ text: "2015년 정보통신시스템 통합유지보수 용역" },
							{ text: "2016년 정보시스템 통합유지보수 용역" },
							{ text: "2016년도 서부평생학습관 모바일앱 시스템 유지보수" },
							{ text: "2016년 홈페이지 유지보수" },
						],
					},
				],
			},
		],
		// 협력사
		cooperationTabCurrent: "cooperationTab0",
		cooperationList: [
			{
				title: "공공기관",
				value: "cooperationTab0",
				length: 37,
			},
			{
				title: "대학교ㆍ병원",
				value: "cooperationTab1",
				length: 7,
			},
			{
				title: "민간기업",
				value: "cooperationTab2",
				length: 1,
			},
		],
}