export default {
    install(Vue, options) {
        Vue.prototype.filterData = (target, type) => {
            let filterData = target.filter((item) => item.type === type);
            filterData.map((item, index) => (item.index = index));
        }


        Vue.prototype.isMobile = () => {
            if (767 >= window.innerWidth) {
                return true;
            } else {
                return false;
            }
        }
        Vue.prototype.isLaptop = () => {
            if (1025 >= window.innerWidth) {
                return true;
            } else {
                return false;
            }
        }
    }
}
