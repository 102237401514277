<template>
	<div class="ict">
		<p class="slogon">
			풍부한 사업 경험과 핵심 기술력, 각 분야 최고의 솔루션을 기반으로 고객의
			요구와 비즈니스 환경에 최적화된 IT시스템을 구축하고 제공합니다.
			<br />전문화된 컨설팅부터 시스템 구축까지, 고객 비즈니스에 대한 이해를
			갖춘 각 분야 전문 인력을 통해 고객 중심 IT서비스를 제공합니다.
		</p>
		<article class="smart">
			<div class="wrapper">
				<div class="context">
					<h3>Smart Information</h3>
					<p>
						도서관 이용을 위한 층별안내, 이용안내, 이벤트 안내정보 등의
						이용정보를 제공하고 이용자가 화면을 터치하여 사용하는 인터렉티브
						시스템
					</p>
				</div>
				<div class="smart__ev figure">
					<img src="@/assets/images/works/ict/smart_product0.png" alt="" />
					<p class="figure__label">
						<img src="@/assets/images/icon/triangle_left_black.png" alt="" />
						E/V Display
					</p>
				</div>
				<div class="smart__kiosk figure">
					<img src="@/assets/images/works/ict/smart_product1.png" alt="" />
					<p class="figure__label">
						KIOSK
						<img src="@/assets/images/icon/triangle_right_black.png" alt="" />
					</p>
				</div>
			</div>
		</article>
		<div class="list wrapper">
			<article>
				<h3>Smart Book Recommendation System</h3>
				<p>
					스마트 도서추천 시스템은 신착도서 추천도서등의 도서 리스트및
					상세정보를 제공 하는 시스템으로 도서위치 찾기나 RFID 인증을 통한 나의
					도서 대출 목록 등을 확인 할 수 있는 시스템 입니다.
				</p>
				<img src="@/assets/images/works/ict/recommendation_image.jpg" alt="" />
			</article>
			<article>
				<h3>Smart Multimedia</h3>
				<p>
					다양한 멀티미디어 신작 자료나 사서가 추천하는 멀티미디어 자료 정보를
					제공해주는 시스템. CMS로 연동하여 관리하기 용이.
				</p>
				<img src="@/assets/images/works/ict/multimedia_image.jpg" alt="" />
			</article>
			<article>
				<h3>Multi Display Information System</h3>
				<p>
					도서관 이용정보를 제공하는 디스플레이 시스템으로 다양한 정보를
					효과적으로 전달 가능하며, 추가/변동 되는 정보를 통합관리시스템을
					이용하여 실시간으로 변경 적용 가능
				</p>
				<img src="@/assets/images/works/ict/iInformation_image.jpg" alt="" />
			</article>
			<article>
				<h3>Reservation &amp; Management System</h3>
				<p>
					실 예약관리시스템과 연계하여 이용자들에 대한 출입통제 및 룸
					예약/이용정보 등을 제공하는 시스템
				</p>
				<img src="@/assets/images/works/ict/reservation_image.jpg" alt="" />
			</article>
			<article>
				<h3>Smart Digital Aqualium</h3>
				<p>
					개인 물고기 생성, 로그인 시 책가방을 맨 개인 물고기가 화면에 출현하고
					터치 시 팝업창이 생성되어 개인 대출 이력, 쪽지함, 포인트 정보 등의
					내용을 확인 할 수 있도록 인터페이스 구성
				</p>
				<img src="@/assets/images/works/ict/aqualium_image.jpg" alt="" />
			</article>
			<article>
				<h3>Media Wall System</h3>
				<p>
					대형안내 시스템은 멀티그래픽 시스템과 슬림베젤 디스플레이를 이용하여
					구현하는 시스템으로 대형화면을 이용하여 효과적인 정보 제공이 가능한
					시스템
				</p>
				<img src="@/assets/images/works/ict/mediawall_image.jpg" alt="" />
			</article>
		</div>
	</div>
</template>

<script>
export default {
	name: "Ict",
	data: () => {
		return {
			scrollY: 0,
			timer: null,
		};
	},
	methods: {},
	created: function () {
		setTimeout(() => {
			document.querySelector(".smart").classList.add("animate");
		}, 500);
	},
	mounted: function () {
		window.addEventListener("scroll", this.handleScroll);
	},
	beforeDestroy: function () {
		window.removeEventListener("scroll", this.handleScroll);
	},
	methods: {
		handleScroll: function () {
			var article = document.querySelectorAll(".list article");
			if (this.timer === null) {
				this.timer = setTimeout(() => {
					this.scrollY = window.scrollY;
					article.forEach((item) => {
						var articleTop =
							window.pageYOffset + item.getBoundingClientRect().top - 500;
						if (this.scrollY >= articleTop) {
							item.classList.add("animate");
						}
					});
					clearTimeout(this.timer);
					this.timer = null;
				}, 1);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
</style>