<template>
	<section id="works" class="works subpage">
		<div class="head">
			<div class="wrapper">
				<div class="head__left">
					<h2 class="head__title">WORKS</h2>
				</div>
				<div class="head__right">
					<Tabs :tabs="tabsList" />
				</div>
			</div>
		</div>
		<transition name="fade">
		<router-view></router-view>
		</transition>
	</section>
</template>

<script>
import Header from "@/components/common/header";
import Tabs from "@/components/modules/tabs";

export default {
	name: "Works",
	components: {
		Tabs,
	},
	data() {
		return {
			tabsList: [
				{ link: "/works/consulting", title: "공간컨설팅" },
				{ link: "/works/ict", title: "ICT 시스템" },
				{ link: "/works/system", title: "시스템통합" },
			],
		};
	},
};
</script>

<style lang="scss" scope>
@import "~@/assets/css/scss/works";

</style>