<template>
	<div id="project" class="project subpage">
		<div class="head">
			<div class="wrapper">
				<div class="head__left">
					<h2 class="head__title">Project</h2>
				</div>
				<div class="head__right">
					<Tabs :tabs="tabsList" />
				</div>
			</div>
		</div>
		<transition name="fade-delay">
		<router-view></router-view>
		</transition>
	</div>
</template>

<script>
import projectData from "./project-data";
import Tabs from "@/components/modules/tabs";

export default {
	name: "Project",
	components: {
		Tabs,
	},
	data: () => projectData,
};
</script>

<style lang="scss">
@import "~@/assets/css/scss/project";
</style>