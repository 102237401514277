<template>
    <div id="main" class="mainpage">
        <div class="mainProject fullpage">
            <div
                class="mainProject__wrapper fullpage__wrapper"
                v-fullpage="mainProjectOpts"
                ref="mainProject"
            >
                <div
                    v-for="(mainProject, mainProjectIndex) in mainData"
                    :key="mainProject.key"
                    :class="{ active: mainProjectIndex == mainProjectActive }"
                    class="mainProject__page"
                >
                    <div
                        v-if="mainProjectIndex != mainProjectActive"
                        :style="{
                            'background-image': `url(${mainProject.mainImage[0]})`,
                        }"
                        class="mainProject__cover background"
                        alt=""
                    ></div>
                    <div
                        v-if="mainProjectIndex == mainProjectActive"
                        class="mainProjectSlider swiper-container"
                    >
                        <div class="swiper-wrapper">
                            <div
                                v-for="(image,
                                imageIndex) in mainProject.mainImage"
                                :key="image.key"
                                :class="
                                    `swiper-slide swiper-slide--${imageIndex}`
                                "
                            >
                                <div
                                    :style="{
                                        'background-image': `url(${image})`,
                                    }"
                                    class="mainProjectSlider__image background"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mainProject__paging">
                <div
                    v-for="(mainProject, mainProjectIndex) in mainData"
                    :key="mainProject.key"
                    :class="{ active: mainProjectIndex == mainProjectActive }"
                    @click="mainProjectMoveTo(mainProjectIndex)"
                    class="bullet"
                ></div>
            </div>
            <small class="copyright"
                >COPYRIGHT(C) 2021 BY NETCOMM, INC. ALL RIGHTS RESERVED.</small
            >
        </div>
        <div class="fixedMenu">
            <div class="fixedMenu__list">
                <div
                    class="fixedMenu__item"
                    v-for="(mainProject, mainProjectIndex) in mainData"
                    :key="mainProject.key"
                    :class="{ active: mainProjectIndex == mainProjectActive }"
                >
                    <div class="fixedMenu__bar">
                        <transition name="fade">
                            <div
                                v-if="mainProjectIndex == mainProjectActive"
                                class="mainProjectSliderScrollbar"
                            ></div>
                        </transition>
                    </div>
                    <button
                        type="button"
                        class="fixedMenu__button"
                        @click="mainProjectMoveTo(mainProjectIndex)"
                    >
                        <span class="fixedMenu__number">{{
                            9 > mainProjectIndex
                                ? "0" + (mainProjectIndex + 1)
                                : mainProjectIndex + 1
                        }}</span>
                        <strong class="fixedMenu__title">{{
                            mainProject.titleKr
                        }}</strong>
                    </button>
                    <transition name="fade">
                        <a
                            v-if="mainProjectIndex == mainProjectActive"
                            :href="
                                `/#/project/${mainProject.type}/view/${mainProject.index}`
                            "
                            class="fixedMenu__link"
                        >
                            View Project
                        </a>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import projectData from "../project/project-data";
import Swiper, { EffectFade, Autoplay, Scrollbar } from "swiper";
import "swiper/swiper-bundle.css";
Swiper.use([EffectFade, Autoplay, Scrollbar]);

var swiperOptions = {
    observer: true,
    observeParents: true,
    speed: 1000,
    effect: "fade",
    fadeEffect: {
        crossFade: true,
    },
    autoplay: {
        delay: 2500,
    },
    scrollbar: {
        el: ".active .mainProjectSliderScrollbar",
        draggable: true,
    },
    on: {
        reachBeginning: function() {
            triggerPager();
        },
    },
};
var swipeSlider;
function swiper() {
    if (swipeSlider !== undefined) {
        swipeSlider.destroy();
    }
    swipeSlider = new Swiper(".mainProjectSlider", swiperOptions);
}

function triggerPager() {
    var bulletIndex = document.querySelectorAll(".bullet");
    var bi = 0;
    bulletIndex.forEach(function(e, i) {
        if (e.classList.contains("active")) {
            bi = i;
        }
    });
    bi > bulletIndex.length - 2 ? (bi = 0) : (bi = bi + 1);
    bulletIndex[bi].click();
}

projectData.mainProjectActive = 0;
projectData.mainProjectOpts = {
    start: projectData.mainProjectActive,
    dir: "v",
    afterChange: function() {
        this.vm.mainProjectActive = this.curIndex;
        setTimeout(() => {
            swiper();
        }, 100);
    },
};

export default {
    name: "Main",
    components: {},
    data: () => projectData,
    mounted() {
        this.$nextTick(() => {
            swiper();
        });
        this.mainProjectActive = 0;
    },
    computed: {
        mainData: function() {
            this.filterData(this.projectList, "public");
            this.filterData(this.projectList, "university");
            this.filterData(this.projectList, "etc");
            return this.projectList.filter(
                (item) => item.mainSelected === true
            );
        },
    },
    methods: {
        mainProjectMoveTo(index) {
            this.$refs.mainProject.$fullpage.moveTo(
                index,
                true /*animatied*/,
                true /*force move?*/
            );
            this.mainProjectActive = this.$refs.mainProject.$fullpage.curIndex;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/scss/main";
</style>

<style lang="scss">
.mainProjectSliderScrollbar {
    height: 100%;
    .swiper-scrollbar-drag {
        height: 100%;
        background-color: #f00;
        cursor: pointer;
        &:hover {
            box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.2);
        }
    }
}
</style>
