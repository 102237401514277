<template>
	<transition name="fade">
		<button v-if="isShow" @click="topButton()" class="topButton">
			<span class="hidden">상단으로 바로가기</span>
		</button>
	</transition>
</template>

<script>
export default {
	name: "TopButton",
	data: () => {
		return {
			isShow : false
		};
	},
	mounted: function () {
		window.addEventListener("scroll", () => this.handleScroll());
	},
	methods: {
		handleScroll: function () {
			console.log()
			if (window.scrollY > 0) {
				this.isShow = true;
			} else if (window.scrollY == 0) {
				this.isShow = false;
			}
		},
		topButton() {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/scss/_mixin.scss";

.topButton {
	position: fixed;
	right: 7.5%;
	bottom: 10%;
	z-index: 50;
	width: 3.75rem;
	height: 3.75rem;
	background: #333333 url(~@/assets/images/common/topButton_icon.png) 50% 50%
		no-repeat;
	background-size: 33.33%;
	@media (max-width: 1600px) {
		right: 1.5%;
		bottom: 3.5%;
		width: 3rem;
		height: 3rem;
	}
	@include respond("mobile") {
		width: 2.25rem;
		height: 2.25rem;
	}
}
</style>
