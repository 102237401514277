<template>
  <section id="about" class="about subpage">
    <div class="head">
      <div class="wrapper">
        <div class="head__left">
          <h2 class="head__title">ABOUT</h2>
        </div>
        <div class="head__right">
          <p>
            <strong>(주)네트콤은 디지털 복합 공간 전문기업입니다.</strong>
          </p>
          <p>
            IT업계 최고의 정보통신 기술보유를 기반으로 1996년 보령그룹 계열사로
            설립되어 현재까지 대내외 정보통신 프로젝트를 성공적으로 수행
            하였으며, 특히 도서관 및 기업 학술정보관 사업 분야의 정보화 시스템
            구축에 선두회사로 성장하고 있습니다.
          </p>
          <!-- <a
                        :href="`${publicPath}file/netcomm_introduction.pdf`"
                        class="download"
                        download="(주)네트콤 회사소개서"
                    >
                        <i class="download__icon">
                            <img
                                src="@/assets/images/icon/download_white.png"
                                alt=""
                            />
                        </i>
                        <span class="download__text">회사소개서 다운로드</span>
                    </a> -->
        </div>
      </div>
    </div>
    <article class="history">
      <div class="wrapper">
        <h3 class="hidden">연혁</h3>
        <ol class="history__list">
          <li
            v-for="history in historyList"
            :key="history.key"
            class="history__item"
          >
            <strong class="year">{{ history.year }}</strong>
            <ul class="context">
              <li
                v-for="contenxt in history.contenxt"
                :key="contenxt.key"
                class="context__item"
              >
                <span class="month">{{ contenxt.month }}</span>
                <span class="text">{{ contenxt.text }}</span>
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </article>
    <article class="award">
      <div class="wrapper">
        <h3>특허 및 인증</h3>
        <div class="patent__slider swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(patent, patentIndex) in patentList"
              :key="patent.key"
            >
              <div class="award__item">
                <img
                  :src="
                    require('@/assets/images/about/patent_image' +
                      patentIndex +
                      '.png')
                  "
                  alt=""
                />
                <p class="award__name">
                  <span>{{ patent.name }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="swiper-components">
            <div class="swiper-paging">
              <div class="swiper-paging-text swiper-paging-current">
                1
              </div>
              <div class="swiper-paging-bar"></div>
              <div class="swiper-paging-text swiper-paging-total"></div>
            </div>
            <button href="#" class="swiper-navigation swiper-navigation-prev">
              <span class="hidden">이전 슬라이드</span>
            </button>
            <button href="#" class="swiper-navigation swiper-navigation-next">
              <span class="hidden">다음 슬라이드</span>
            </button>
          </div>
        </div>
      </div>
    </article>
    <article class="award">
      <div class="wrapper">
        <h3>면허 및 수상</h3>
        <div class="license__slider swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(license, licenseIndex) in licenseList"
              :key="license.key"
            >
              <div class="award__item">
                <img
                  :src="
                    require('@/assets/images/about/license_image' +
                      licenseIndex +
                      '.png')
                  "
                  alt=""
                />
                <p class="award__name">
                  <span>{{ license.name }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="swiper-components">
            <div class="swiper-paging">
              <div class="swiper-paging-text swiper-paging-current">
                1
              </div>
              <div class="swiper-paging-bar"></div>
              <div class="swiper-paging-text swiper-paging-total"></div>
            </div>
            <button href="#" class="swiper-navigation swiper-navigation-prev">
              <span class="hidden">이전 슬라이드</span>
            </button>
            <button href="#" class="swiper-navigation swiper-navigation-next">
              <span class="hidden">다음 슬라이드</span>
            </button>
          </div>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import aboutData from "./about-data";
import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
Swiper.use([Navigation, Pagination]);

function swiperInit(slider) {
  var pagingCurrent = document.querySelector(
    `${slider} .swiper-paging-current`
  );
  var pagingTotal = document.querySelector(`${slider} .swiper-paging-total`);
  pagingTotal.innerHTML = document.querySelectorAll(
    `${slider} .swiper-slide`
  ).length;

  var swiper = new Swiper(slider, {
    observer: true,
    observeParents: true,
    loop: true,
    slidesPerView: 2,
    breakpoints: {
      767: {
        slidesPerView: 3,
      },
      1025: {
        slidesPerView: 4,
      },
      1367: {
        slidesPerView: 5,
      },
    },
    navigation: {
      nextEl: `${slider} .swiper-navigation-next`,
      prevEl: `${slider} .swiper-navigation-prev`,
    },
    pagination: {
      el: `${slider} .swiper-paging-bar`,
      type: "progressbar",
      clickable: true,
    },
  });
  swiper.on("slideChange", function(slider) {
    pagingCurrent.innerHTML = slider.realIndex + 1;
  });
}

aboutData.publicPath = process.env.BASE_URL;

export default {
  name: "About",
  data: () => aboutData,
  mounted() {
    swiperInit(".patent__slider");
    swiperInit(".license__slider");
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/scss/about";
</style>
