<template>
	<article id="" class="projectList">
		<div class="wrapper">
			<h3 class="hidden">프로젝트 목록</h3>
			<div class="projectList__list">
				<projectList-item
					v-for="list in listData"
					:key="list.key"
					:visible="$route.name == list.type || $route.name == 'all'"
					:class="{
						active: $route.name == list.type || $route.name == 'all',
					}"
					class="projectList__item"
				>
					<router-link
						:to="`/project/${list.type}/view/${list.index}`"
						class="projectList__thumbnail thumbnail"
					>
						<img :src="list.image.thumbnail" alt="" />
					</router-link>
					<strong class="projectList__title">{{ list.titleKr }}</strong>
					<div class="projectList__date">{{ list.date }}</div>
				</projectList-item>
			</div>
				<pagination
					:page-count="Math.ceil(totalData.length / this.pagingMax)"
					:page-range="4"
					:prev-text="'<img src='+require('@/assets/images/project/pagination_button_prev.jpg')+'>'"
					:next-text="'<img src='+require('@/assets/images/project/pagination_button_next.jpg')+'>'"
					:click-handler="clickCallback"
					:container-class="'pagination'"
				>
				</pagination>
		</div>
	</article>
</template>

<script>
import projectData from "./project-data";
import Pagination from "vuejs-paginate";
const _ = require("lodash");

Array.prototype.division = function (n) {
	var arr = this;
	var len = arr.length;
	var cnt = Math.floor(len / n);
	var tmp = [];

	for (var i = 0; i <= cnt; i++) {
		tmp.push(arr.splice(0, n));
	}

	return tmp;
};
projectData.pagingMax = 12;  // 리스트에서 보여질 최대 프로젝트 개수

export default {
	name: "projectList",
	components: {
		"projectList-item": {
			props: ["visible"],
			template:
				'<transition name="fade" mode="in-out" appear><div v-if="visible"><slot/></div></transition>',
		},
		pagination: Pagination,
	},
	data: () => projectData,
	computed: {
		totalData: function () {
			let data;
			if (this.$route.name == "all") {
				this.filterData(projectData.projectList, "university");
				this.filterData(projectData.projectList, "public");
				this.filterData(projectData.projectList, "etc");
				data = this.projectList;
			} else {
				data = this.projectList.filter(
					(item) => item.type === this.$route.name
				);
				data.map((item, index) => (item.index = index));
			}
			return data;
		},
		listData: function () {
			let data = _.cloneDeep(this.totalData);
			data = data.division(projectData.pagingMax);
			console.log(this.$route)
			return data[this.$route.params.index];
		},
	},
	updated() {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
	},
	methods: {
		clickCallback: function (pageNum) {
			var num = pageNum-1
			if (this.$route.params.index != num) {
				this.$router.push({path: String(num)})
			}
		},
	},
};
</script>

<style lang="scss" scoped>
</style>
