<template>
	<div class="footer">
		<div class="wrapper">
			<div class="footer__context">
				<p>
					07202 서울특별시 영등포구 양평로30길 14 905호 TEL 02-2677-0212 FAX
					02-2677-0218
				</p>
				<small>COPYRIGHT(C) 2021 BY NETCOMM, INC. ALL RIGHTS RESERVED.</small>
			</div>
			<ul class="logo">
				<li
					v-for="(logo, logoIndex) in logoLength"
					:key="logo.key"
					class="logo__item"
				>
					<img
						:src="require(`@/assets/images/common/footer_logo${logoIndex}.jpg`)"
						alt=""
					/>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: "Footer",
	data() {
		return {
			logoLength: 7,
		};
	},
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/scss/common";
</style>